// import React from "react";

// const SmallCircularProgress = ({ percentage }) => {
//   const strokeDasharray = `${percentage}, 100`;

//   return (
//     <div className="small-circular-progress-container">
//       <svg className="small-progress-circle" viewBox="0 0 36 36">
//         {/* Background Circle */}
//         <path
//           className="circle-bg"
//           d="M18 2.0845
//              a 15.9155 15.9155 0 0 1 0 31.831
//              a 15.9155 15.9155 0 0 1 0 -31.831"
//           stroke="#ddd"
//           strokeWidth="2"
//           fill="none"
//         />

//         {/* Foreground Circle */}
//         <path
//           className="circle"
//           strokeDasharray={strokeDasharray}
//           d="M18 2.0845
//              a 15.9155 15.9155 0 0 1 0 31.831
//              a 15.9155 15.9155 0 0 1 0 -31.831"
//           stroke="#F7931D"
//           strokeWidth="3"
//           fill="none"
//         />

//         {/* Percentage Text in the center */}
//         <text
//           x="18"
//           y="20.35"
//           textAnchor="middle"
//           fontSize="8"
//           fill="#fff"
//           fontWeight="bold"
//           //   color="#ffffff"
//         >
//           {percentage}
//         </text>
//       </svg>
//     </div>
//   );
// };

// export default SmallCircularProgress;

import React from "react";

const SmallCircularProgress = ({ percentage }) => {
  const strokeDasharray = `${percentage}, 100`;
  const circleColor = percentage === 100 ? "#4caf50" : "#F7931D";

  return (
    <div className="small-circular-progress-container">
      <svg className="small-progress-circle" viewBox="0 0 36 36">
        <path
          className="circle-bg"
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke="#ddd"
          strokeWidth="2"
          fill="none"
        />

        <path
          className="circle"
          strokeDasharray={strokeDasharray}
          d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke={circleColor}
          strokeWidth="3"
          fill="none"
        />

        {/* Percentage Text in the center */}
        <text
          x="18"
          y="20.35"
          textAnchor="middle"
          fontSize="8"
          fill="#fff"
          fontWeight="bold"
        >
          {percentage}%
        </text>
      </svg>
    </div>
  );
};

export default SmallCircularProgress;
