import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import sessionstorageService from "services/sessionstorage.service";
import "../../../../../utils/create-admission.css";
import CreateAcademicProfessional from "../create-admission/create-academic-professional/CreateAcademicProfessional";
import CreateEmployementWorkExperience from "../create-admission/create-employement-work-experience/CreateEmployementWorkExperience";
import CreateFinancial from "../create-admission/create-financial/CreateFinancial";
import CreateGuardianDetails from "../create-admission/create-guardian-details/CreateGuardianDetails";
import CreateListUploadsFiles from "../create-admission/create-list-uploads-files/CreateListUploadsFiles";
import CreateMailingAddress from "../create-admission/create-mailing-address/CreateMailingAddress";
import CreateOtherInformation from "../create-admission/create-other-information/CreateOtherInformation";
import CreatePersonalData from "../create-admission/create-personal-data/CreatePersonalData";
import CreateProgramDetials from "../create-admission/create-program-details/CreateProgramDetails";
import CreateStudentProfile from "../create-admission/create-student-profile/CreateStudentProfile";
import ViewAdmission from "../view-admission/ViewAdmission";
import CloaPaymentInfo from "../create-admission/cloa-payment-info/CloaPaymentInfo";
import PaymentSubmission from "../create-admission/payment-submission/PaymentSubmission";

function AdmissionRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);
    return userPermissions;
  }

  useEffect(() => {
    if (location.pathname === "/admission-management") {
      navigate("/admission-management/view-admission");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      {hasPermission("Admission Management: view") && (
        <Route
          path="create-program-details"
          element={<CreateProgramDetials />}
        />
      )}
      {hasPermission("Admission Management: view") && (
        <Route path="create-financial" element={<CreateFinancial />} />
      )}
      {hasPermission("Admission Management: view") && (
        <Route path="payment-info" element={<PaymentSubmission />} />
      )}
      {hasPermission("Admission Management: view") && (
        <Route path="create-personal-data" element={<CreatePersonalData />} />
      )}
      {hasPermission("Admission Management: view") && (
        <Route
          path="create-mailing-address"
          element={<CreateMailingAddress />}
        />
      )}
      {hasPermission("Admission Management: view") && (
        <Route
          path="create-guardian-details"
          element={<CreateGuardianDetails />}
        />
      )}
      {hasPermission("Admission Management: view") && (
        <Route
          path="create-academic-professional"
          element={<CreateAcademicProfessional />}
        />
      )}
      {hasPermission("Admission Management: view") && (
        <Route
          path="create-employement-work-experience"
          element={<CreateEmployementWorkExperience />}
        />
      )}
      {hasPermission("Admission Management: view") && (
        <Route
          path="create-other-information"
          element={<CreateOtherInformation />}
        />
      )}
      {hasPermission("Admission Management: view") && (
        <Route
          path="create-list-upload-files"
          element={<CreateListUploadsFiles />}
        />
      )}
      {hasPermission("Admission Management: view") && (
        <Route
          path="create-student-profile"
          element={<CreateStudentProfile />}
        />
      )}
      {hasPermission("Admission Management: view") && (
        <Route path="cloa-payment-info" element={<CloaPaymentInfo />} />
      )}
      {hasPermission("Admission Management: view") && (
        <Route path="view-admission" element={<ViewAdmission />} />
      )}
    </Routes>
  );
}

export default AdmissionRoutes;
