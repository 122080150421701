import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import * as Yup from "yup";
import { ADMISSION_MANAGEMENT_URL } from "../constants/admission-management-urls";
import "./CreateListUploadsFiles.css";
import { toast } from "react-toastify";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCloseCircleFill } from "react-icons/ri";
import AdmissionSideBarFaculty from "../admission-side-bar-faculty/AdmissionSideBarFaculty";
import { FaEye } from "react-icons/fa";
import sessionService from "services/sessionstorage.service";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";

function CreateListUploadsFiles() {
  const handleRejectButtonClick = () => {
    setShowRejectInput(true);
    setTimeout(() => {
      const commentsContainer = document.getElementById(
        "rejection-comments-container"
      );
      if (commentsContainer) {
        commentsContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };
  const location = useLocation();
  const navigation = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [admissionData, setAdmissionData] = useState(false);
  const [showRejectButton, setShowRejectButton] = useState(false);
  const [showApproveButton, setShowApproveButton] = useState(false);

  console.log(admissionData?.user?.hashList, "admissssssss");

  const data = location?.state?.data;
  const userData = sessionService.getUserData();

  const [studentType, setStudentType] = useState(null);
  useEffect(() => {
    if (data?.admissionId) {
      axiosService
        .get(
          `${ADMISSION_MANAGEMENT_URL.GET_ADMISSION_URL_BY_ID}${data?.admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setShowRejectButton(data?.studentPayments[0]?.isApproved !== true);
          setShowApproveButton(!data?.upload?.isApproved);
          setStudentType(data?.user?.studentType);
        });
    }
  }, [location.pathname, refresh]);

  const initialValues = {
    degree: null,
    studyPermit: null,
    workPermit: null,
    englishExamResults: null,
    passport: null,
    wonderlicTest: null,
    cv: null,
    passportSizePhoto: null,
    photoId: null,
    socialInsuranceNumber: null,
    noticeOfAssessment: null,
    declarationForm: null,
  };

  const validationSchema = Yup.object().shape({});

  const navigateToUploadFiles = () => {
    navigation("/admission-management/create-other-information", {
      state: { data: data },
    });
  };
  const navigateToProfile = () => {
    navigation("/admission-management/create-student-profile", {
      state: { data: data },
    });
  };

  const onSubmit = async (values) => {
    navigation("/admission-management/create-student-profile", {
      state: { data: data },
    });
  };

  const [showRejectInput, setShowRejectInput] = useState(false);

  const handleApprove = async () => {
    let requestData = admissionData?.upload;
    requestData.isApproved = true;
    requestData.isRejected = false;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_UPLOADFILE}`,
        requestData
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.success("Files Approved Successfully");
        navigateToProfile();
      } else {
      }
    } catch (error) {}
  };
  const [rejectComments, setRejectComments] = useState("");
  const handleReject = async () => {
    let requestData = admissionData?.upload;
    requestData.isApproved = false;
    requestData.isRejected = true;
    requestData.rejectComments = rejectComments;

    try {
      const response = await axiosService.put(
        `${ADMISSION_MANAGEMENT_URL.PUT_UPLOADFILE}`,
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        setRefresh(!refresh);
        toast.error("Files Rejected");
        setShowRejectInput(false);
      } else {
      }
    } catch (error) {}
  };

  const dateString = new Date(admissionData?.upload?.updatedOn);

  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleViewClick = async (value) => {
    console.log(value, "aaaa");

    let filteredData = admissionData?.user?.hashList.filter(
      (a) => a.userUpload === value
    );

    const typo = filteredData[0]?.fileName
      ? filteredData[0].fileName.includes(".pdf")
        ? "application/pdf"
        : filteredData[0].fileName.includes(".jpeg")
        ? "image/jpeg"
        : filteredData[0].fileName.includes(".png")
        ? "image/png"
        : filteredData[0].fileName.includes(".jpg")
        ? "image/jpg"
        : "unknown"
      : null;

    console.log(filteredData, "filteredData");

    try {
      const response = await axiosService.get(
        `${DOCUMENT_URLS.DECRYPT_MINIO_UPLOAD}?hashValue=${filteredData[0]?.hashValue}`
      );

      const base64String = response?.data?.data?.body; // Adjust key if needed

      console.log(base64String, "base64string");

      // Determine the file type and construct a data URL
      // const fileType = "application/pdf"; // Change to "image/jpeg" for JPEG images, etc.
      // const blobUrl = `data:${fileType};base64,${base64String}`;

      // if (blobUrl) {
      //   window.open(blobUrl, "_blank");
      // }

      const binaryString = atob(base64String);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the binary data
      const blob = new Blob([bytes], { type: typo });
      const blobUrl = URL.createObjectURL(blob);

      // Open the Blob URL in a new tab and clean up the URL afterward
      const newTab = window.open(blobUrl, "_blank");
      if (newTab) {
        newTab.onload = () => URL.revokeObjectURL(blobUrl); // Clean up the blob URL once opened
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  function handleClick(event) {
    event.preventDefault();
    return;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className="create-admission-main-container">
            <div className="progress-container">
              <div className="create-admission-head-text">
                Application #{admissionData?.programDetails?.applicationNo}
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBarFaculty data={data} />
              </div>

              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2">
                <div className="create-text-title ">
                  List of Uploaded files.
                </div>
                <div>
                  <div className="create-list-upload-main-input-container">
                    <div className="create-list-upload-inner-input-container">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="degree"
                        >
                          Highest Qualification Passing Certificate *
                        </label>
                        <input
                          disabled={true}
                          className="d-none"
                          type="file"
                          accept="image/*"
                          id="upload1"
                          name="degree"
                          onChange={(event) =>
                            setFieldValue(
                              "degree",
                              event.currentTarget.files[0]
                            )
                          }
                        />
                        <span
                          className="create-list-upload-input-big"
                          onClick={() => {
                            document.getElementById("upload1").click();
                          }}
                        >
                          <span className="text-white">
                            {admissionData?.upload?.degreeName ||
                              "No file chosen"}
                          </span>
                          <div className="create-list-upload-input-choose">
                            Choose File
                          </div>
                        </span>
                      </div>
                      {admissionData?.upload?.degree && (
                        <a
                          href={admissionData?.upload?.degree}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>

                    <div className="create-list-upload-inner-input-container">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="passportSizePhoto"
                        >
                          Latest Color Passport Size Photo *
                        </label>
                        <input
                          disabled={true}
                          className="d-none"
                          type="file"
                          accept="image/*"
                          id="upload2"
                          name="passportSizePhoto"
                          onChange={(event) =>
                            setFieldValue(
                              "passportSizePhoto",
                              event.currentTarget.files[0]
                            )
                          }
                        />
                        <span
                          className="create-list-upload-input-big"
                          onClick={() => {
                            document.getElementById("upload2").click();
                          }}
                        >
                          <span className="text-white">
                            {admissionData?.upload?.passportSizePhotoName ||
                              "No file chosen"}
                          </span>
                          <div className="create-list-upload-input-choose">
                            Choose File
                          </div>
                        </span>
                      </div>
                      {admissionData?.upload?.passportSizePhoto && (
                        <a
                          href={admissionData?.upload?.passportSizePhoto}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>
                    {studentType !== "OSAP" && (
                      <div className="create-list-upload-inner-input-container">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload3"
                          >
                            English Proficiency Test Scorecard *
                            <br /> (IELTS/TOFEL/PTE)
                          </label>
                          <input
                            disabled={true}
                            className="d-none"
                            type="file"
                            accept="image/*"
                            id="upload3"
                            name="englishExamResults"
                            onChange={(event) =>
                              setFieldValue(
                                "englishExamResults",
                                event.currentTarget.files[0]
                              )
                            }
                          />
                          <span
                            className="create-list-upload-input-big"
                            onClick={() => {
                              document.getElementById("upload3").click();
                            }}
                          >
                            <span className="text-white">
                              {admissionData?.upload?.englishExamResultsName ||
                                "No file chosen"}
                            </span>
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </span>
                        </div>
                        {admissionData?.upload?.englishExamResults && (
                          <a
                            href={admissionData?.upload?.englishExamResults}
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}

                    <div className="create-list-upload-inner-input-container">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="upload4"
                        >
                          Valid Passport *
                        </label>
                        <input
                          disabled={true}
                          className="d-none"
                          type="file"
                          accept="image/*"
                          id="upload4"
                          name="passport"
                          onChange={(event) =>
                            setFieldValue(
                              "passport",
                              event.currentTarget.files[0]
                            )
                          }
                        />
                        <span
                          className="create-list-upload-input-big"
                          onClick={() => {
                            document.getElementById("upload4").click();
                          }}
                        >
                          <span className="text-white">
                            {admissionData?.upload?.passportName ||
                              "No file chosen"}
                          </span>
                          <div className="create-list-upload-input-choose">
                            Choose File
                          </div>
                        </span>
                      </div>
                      {admissionData?.upload?.passport && (
                        <a
                          // href={admissionData?.upload?.passport}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleViewClick("passport");
                          }}
                          // target="_blank"
                          // rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>

                    {studentType === "OSAP" && (
                      <div className="create-list-upload-inner-input-container">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="photoId"
                          >
                            Photo Id *
                          </label>
                          <input
                            disabled={true}
                            className="d-none"
                            type="file"
                            accept="image/*"
                            id="upload1"
                            name="photoId"
                            onChange={(event) =>
                              setFieldValue(
                                "photoId",
                                event.currentTarget.files[0]
                              )
                            }
                          />
                          <span
                            className="create-list-upload-input-big"
                            // onClick={() => {
                            //   document.getElementById("upload9").click();
                            // }}
                          >
                            <span className="text-white">
                              {admissionData?.upload?.photoIdName ||
                                "No file chosen"}
                            </span>
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </span>
                        </div>
                        {admissionData?.upload?.photoId && (
                          // <a
                          //   href={admissionData?.upload?.photoId}
                          //   target="_blank"
                          //   rel="noreferrer"
                          //   className="view-eye-icon"
                          // >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleViewClick("photoId");
                            }}
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}
                    {studentType === "OSAP" && (
                      <div className="create-list-upload-inner-input-container">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="declarationForm"
                          >
                            Declaration Form *
                          </label>
                          <input
                            disabled={true}
                            className="d-none"
                            type="file"
                            accept="image/*"
                            id="upload1"
                            name="declarationForm"
                            onChange={(event) =>
                              setFieldValue(
                                "declarationForm",
                                event.currentTarget.files[0]
                              )
                            }
                          />
                          <span
                            className="create-list-upload-input-big"
                            // onClick={() => {
                            //   document.getElementById("upload12").click();
                            // }}
                          >
                            <span className="text-white">
                              {admissionData?.upload?.declarationFormName ||
                                "No file chosen"}
                            </span>
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </span>
                        </div>
                        {admissionData?.upload?.declarationForm && (
                          <a
                            href={admissionData?.upload?.declarationForm}
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}

                    <div className="create-list-upload-inner-input-container">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="upload5"
                        >
                          Wonderlic Test
                        </label>
                        <input
                          disabled={true}
                          className="d-none"
                          type="file"
                          accept="image/*"
                          id="upload5"
                          name="wonderlicTest"
                          onChange={(event) =>
                            setFieldValue(
                              "wonderlicTest",
                              event.currentTarget.files[0]
                            )
                          }
                        />
                        <span
                          className="create-list-upload-input-big"
                          onClick={() => {
                            document.getElementById("upload5").click();
                          }}
                        >
                          <span className="text-white">
                            {admissionData?.upload?.wonderlicTestName ||
                              "No file chosen"}
                          </span>
                          <div className="create-list-upload-input-choose">
                            Choose File
                          </div>
                        </span>
                      </div>
                      {admissionData?.upload?.wonderlicTest && (
                        <a
                          href={admissionData?.upload?.wonderlicTest}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>
                    <div className="create-list-upload-inner-input-container">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="upload6"
                        >
                          CV/Resume
                        </label>
                        <input
                          disabled={true}
                          className="d-none"
                          type="file"
                          accept="image/*"
                          id="upload6"
                          name="cv"
                          onChange={(event) =>
                            setFieldValue("cv", event.currentTarget.files[0])
                          }
                        />
                        <span
                          className="create-list-upload-input-big"
                          onClick={() => {
                            document.getElementById("upload6").click();
                          }}
                        >
                          <span className="text-white">
                            {admissionData?.upload?.cvName || "No file chosen"}
                          </span>
                          <div className="create-list-upload-input-choose">
                            Choose File
                          </div>
                        </span>
                      </div>
                      {admissionData?.upload?.cv && (
                        <a
                          href={admissionData?.upload?.cv}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>
                    {studentType !== "OSAP" && (
                      <div className="create-list-upload-inner-input-container">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload7"
                          >
                            Study Permit
                          </label>
                          <input
                            disabled={true}
                            className="d-none"
                            type="file"
                            accept="image/*"
                            id="upload7"
                            name="studyPermit"
                            onChange={(event) =>
                              setFieldValue(
                                "studyPermit",
                                event.currentTarget.files[0]
                              )
                            }
                          />
                          <span
                            className="create-list-upload-input-big"
                            onClick={() => {
                              document.getElementById("upload7").click();
                            }}
                          >
                            <span className="text-white">
                              {admissionData?.upload?.studyPermitName ||
                                "No file chosen"}
                            </span>
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </span>
                        </div>
                        {admissionData?.upload?.studyPermit && (
                          // <a
                          //   href={admissionData?.upload?.studyPermit}
                          //   target="_blank"
                          //   rel="noreferrer"
                          //   className="view-eye-icon"
                          // >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleViewClick("studyPermit");
                            }}
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}
                    {studentType !== "OSAP" && (
                      <div className="create-list-upload-inner-input-container">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload8"
                          >
                            Work Permit
                          </label>
                          <input
                            disabled={true}
                            className="d-none"
                            type="file"
                            accept="image/*"
                            id="upload8"
                            name="workPermit"
                            onChange={(event) =>
                              setFieldValue(
                                "workPermit",
                                event.currentTarget.files[0]
                              )
                            }
                          />
                          <span
                            className="create-list-upload-input-big"
                            onClick={() => {
                              document.getElementById("upload8").click();
                            }}
                          >
                            <span className="text-white">
                              {admissionData?.upload?.workPermitName ||
                                "No file chosen"}
                            </span>
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </span>
                        </div>
                        {admissionData?.upload?.workPermit && (
                          // <a
                          //   href={admissionData?.upload?.workPermit}
                          //   target="_blank"
                          //   rel="noreferrer"
                          //   className="view-eye-icon"
                          // >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleViewClick("workPermit");
                            }}
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}
                    {studentType === "OSAP" && (
                      <div className="create-list-upload-inner-input-container">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="socialInsuranceNumber"
                          >
                            Social Insurance Number (SIN)
                          </label>
                          <input
                            disabled={true}
                            className="d-none"
                            type="file"
                            accept="image/*"
                            id="upload1"
                            name="socialInsuranceNumber"
                            onChange={(event) =>
                              setFieldValue(
                                "socialInsuranceNumber",
                                event.currentTarget.files[0]
                              )
                            }
                          />
                          <span
                            className="create-list-upload-input-big"
                            // onClick={() => {
                            //   document.getElementById("upload10").click();
                            // }}
                          >
                            <span className="text-white">
                              {admissionData?.upload
                                ?.socialInsuranceNumberName || "No file chosen"}
                            </span>
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </span>
                        </div>
                        {admissionData?.upload?.socialInsuranceNumber && (
                          // <a
                          //   href={admissionData?.upload?.socialInsuranceNumber}
                          //   target="_blank"
                          //   rel="noreferrer"
                          //   className="view-eye-icon"
                          // >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleViewClick("socialInsuranceNumber");
                            }}
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}
                    {studentType === "OSAP" && (
                      <div className="create-list-upload-inner-input-container">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="noticeOfAssessment"
                          >
                            Notice Of Assessment (NOA)
                          </label>
                          <input
                            disabled={true}
                            className="d-none"
                            type="file"
                            accept="image/*"
                            id="upload1"
                            name="noticeOfAssessment"
                            onChange={(event) =>
                              setFieldValue(
                                "noticeOfAssessment",
                                event.currentTarget.files[0]
                              )
                            }
                          />
                          <span
                            className="create-list-upload-input-big"
                            // onClick={() => {
                            //   document.getElementById("upload11").click();
                            // }}
                          >
                            <span className="text-white">
                              {admissionData?.upload?.noticeOfAssessmentName ||
                                "No file chosen"}
                            </span>
                            <div className="create-list-upload-input-choose">
                              Choose File
                            </div>
                          </span>
                        </div>
                        {admissionData?.upload?.noticeOfAssessment && (
                          <a
                            href={admissionData?.upload?.noticeOfAssessment}
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex gap-3 my-3 flex-wrap">
                  <button
                    className="create-admission-previous-button"
                    onClick={navigateToUploadFiles}
                  >
                    Previous
                  </button>
                  <button
                    className="create-admission-save-button"
                    type="button"
                    onClick={navigateToProfile}
                  >
                    Next
                  </button>
                  {showRejectButton && (
                    <button
                      type="button"
                      className="create-admission-previous-button"
                      onClick={handleRejectButtonClick}
                    >
                      Reject
                    </button>
                  )}

                  {showApproveButton && (
                    <button
                      type="button"
                      className="create-admission-save-button"
                      onClick={handleApprove}
                    >
                      Approve & Next
                    </button>
                  )}
                </div>
                <div>
                  {admissionData?.upload?.isApproved && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <span className="approve-textarea">
                        <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                        Approved
                      </span>
                      <span className="approve-date-text">
                        on {formattedDate}
                      </span>
                    </div>
                  )}
                  {admissionData?.upload?.isRejected && (
                    <div className="row approve-reject-comments-container">
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <div className="rejected-container p-0 mt-2">
                        <div className="rejcted-head-text">
                          <RiCloseCircleFill className="admission-reject-icon" />
                          Rejected
                        </div>
                        <div className="inner-reject-text">
                          {admissionData?.upload?.rejectComments}
                        </div>
                      </div>
                      <span className="approve-date-text">{formattedDate}</span>
                    </div>
                  )}
                  {showRejectInput && (
                    <div
                      className="row approve-reject-comments-container"
                      id="rejection-comments-container"
                    >
                      <span className="admission-comments-head-text">
                        Comments
                      </span>
                      <textarea
                        className="reject-textarea"
                        onChange={(event) =>
                          setRejectComments(event.target.value)
                        }
                      />
                      <div className=" d-flex gap-2 p-0 pt-2">
                        <button
                          type="button"
                          className="create-admission-previous-button"
                          onClick={() => setShowRejectInput(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-admission-save-button"
                          onClick={handleReject}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateListUploadsFiles;
