import React from "react";

function StuVector() {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L9 9V0H0Z" fill="#047BC1" />
    </svg>
  );
}

export default StuVector;
