import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FacultyDashboard from "../components/faculty-dashboard/FacultyDashboard";
import StudentDashboard from "../components/student-dashboard/StudentDashboard";
import sessionService from "services/sessionstorage.service";

function DashboardRoutes() {
  const location = useLocation();
  const user = sessionService.getUserData("userData");
  const navigation = useNavigate();
  useEffect(() => {
    if (location.pathname === "/dashboard-management") {
      if (user?.role?.name === "student") {
        navigation("/dashboard-management/student-view");
      } else if (user?.role?.name === "faculty") {
        navigation("/dashboard-management/faculty-view");
      }
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="faculty-view" element={<FacultyDashboard />} />
      <Route path="student-view" element={<StudentDashboard />} />
    </Routes>
  );
}

export default DashboardRoutes;
