const { AppConfig } = require("env.development");

export const DOCUMENT_URLS = {
  DOCUMENT_URL: `${AppConfig.api_url}dashboard/v1/student-view/`,
  HASH_URL: `${AppConfig.api_url.replace("api/", "")}cloa/file-info/student/v1`,
  RECEIPT_URL: `${AppConfig.api_url.replace("api/", "")}file-info/student/v1`,
  ENCRYPTED_MINIO_UPLOAD: `${AppConfig.api_url}file-info/v1/minio`,
  DECRYPT_MINIO_UPLOAD : `${AppConfig.api_url}minio/file-info/v1`,
  GET_CLOA_URL: `${AppConfig.api_url.replace("api/", "")}cloa/file/student/v1`,
  GET_FLOA_URL: `${AppConfig.api_url.replace("api/", "")}floa/file/student/v1`,
  GET_ADMISSION_BY_ID: `${AppConfig.api_url}admission/v1/`,
  GET_DOCUMENT_BY_ID: `${AppConfig.api_url}student-portal/documents/v1/`,

  QR_CODE_URL: `https://erp.lmsmybeta.com/#/qr-code?hashValue`,
};
