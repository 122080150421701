import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { BiCheck } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import "./CreateListUploadsFiles.css";
import sessionstorageService from "services/sessionstorage.service";
import { ADMISSION_URL_FOR_STUDENT } from "../../constants/admission-application-student-urls";
import { ADMISSION_CONSTANTS_FOR_STUDENTS } from "../../constants/admission-application-student-constants";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Dropzone from "react-dropzone";
import AdmissionSideBar from "../admission-side-bar/AdmissionSideBar";
import sessionService from "services/sessionstorage.service";
import sha256 from "crypto-js/sha256";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";

function CreateListUploadsFiles() {
  const location = useLocation();
  const programId = location?.state?.data;
  const [admissionData, setAdmissionData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [isUploading3, setIsUploading3] = useState(false);
  const [isUploading4, setIsUploading4] = useState(false);
  const [isUploading5, setIsUploading5] = useState(false);
  const [isUploading6, setIsUploading6] = useState(false);
  const [isUploading7, setIsUploading7] = useState(false);
  const [isUploading8, setIsUploading8] = useState(false);
  const [isUploading9, setIsUploading9] = useState(false);
  const [isUploading10, setIsUploading10] = useState(false);
  const [isUploading11, setIsUploading11] = useState(false);
  const [isUploading12, setIsUploading12] = useState(false);

  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isFeeData, setIsFeeData] = useState(false);
  const userData = sessionService.getUserData("userData");
  const studentType = userData?.studentType;
  const navigation = useNavigate();
  console.log(userData, "userData");
  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  useEffect(() => {
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
          setApproved(data?.upload?.isApproved);
          setRejected(data?.upload?.isRejected);
          setIsFeeData(data?.fees[0]?.isCompleted);
        });
    }
  }, [location.pathname]);

  const [upload1, setUpload1] = useState(null);
  const [upload2, setUpload2] = useState(null);
  const [upload3, setUpload3] = useState(null);
  const [upload4, setUpload4] = useState(null);
  const [upload5, setUpload5] = useState(null);
  const [upload6, setUpload6] = useState(null);
  const [upload7, setUpload7] = useState(null);
  const [upload8, setUpload8] = useState(null);
  const [upload9, setUpload9] = useState(null);
  const [upload10, setUpload10] = useState(null);
  const [upload11, setUpload11] = useState(null);
  const [upload12, setUpload12] = useState(null);

  const initialValues = {
    degree: null,
    degreeName: null,
    englishExamResults: null,
    englishExamResultsName: null,
    passport: null,
    passportName: null,
    wonderlicTest: null,
    wonderlicTestName: null,
    cv: null,
    cvName: null,
    passportSizePhoto: null,
    passportSizePhotoName: null,
    studyPermit: null,
    studyPermitName: null,
    workPermit: null,
    workPermitName: null,
    photoId: null,
    photoIdName: null,
    socialInsuranceNumber: null,
    socialInsuranceNumberName: null,
    noticeOfAssessment: null,
    noticeOfAssessmentName: null,
    declarationForm: null,
    declarationFormName: null,
    fileId: admissionData?.upload?.fileId,
  };

  const validate = (values) => {
    let errors = {};

    if (!values.degree) {
      errors.degree = "Please upload a file";
    }

    if (!values.passportSizePhoto) {
      errors.passportSizePhoto = "Please upload a file";
    }

    if (!values.passport) {
      errors.passport = "Please upload a file";
    }

    if (studentType !== "OSAP") {
      if (!values.englishExamResults) {
        errors.englishExamResults = "Please upload a file";
      }
    }

    if (studentType === "OSAP") {
      if (!values.photoId) {
        errors.photoId = "Please upload a file";
      }
      if (!values.declarationForm) {
        errors.declarationForm = "Please upload a file";
      }
    }

    return errors;
  };

  const navigateToUploadFiles = () => {
    navigation("/pre-onboarding/create-other-information", {
      state: {
        data: programId,
      },
    });
  };

  const onSubmit = async (values) => {
    values.isRejected = false;
    const dataType = "upload";

    const saveData = {
      dataType,
      studentType,
      data: {
        admissionId: admissionData?.admissionId,
        personal: admissionData?.personalData,
        mailing: admissionData?.mailingAddress,
        guardian: admissionData?.guardianDetail,
        academics: admissionData?.academics,
        employment: admissionData?.employments,
        other: admissionData?.otherInformation,
        upload: {
          degree: upload1 || admissionData.upload?.degree,
          degreeName: values?.degree?.name || admissionData.upload?.degreeName,
          passportSizePhoto: upload2 || admissionData.upload?.passportSizePhoto,
          passportSizePhotoName:
            values?.passportSizePhoto?.name ||
            admissionData.upload?.passportSizePhotoName,

          englishExamResults:
            upload3 || admissionData.upload?.englishExamResults,
          englishExamResultsName:
            values?.englishExamResults?.name ||
            admissionData.upload?.englishExamResultsName,
          passport: upload4 || admissionData.upload?.passport,
          passportName:
            values?.passport?.name || admissionData.upload?.passportName,
          wonderlicTest: upload5 || admissionData.upload?.wonderlicTest,
          wonderlicTestName:
            values?.wonderlicTest?.name ||
            admissionData.upload?.wonderlicTestName,
          cv: upload6 || admissionData.upload?.cv,
          cvName: values?.cv?.name || admissionData.upload?.cvName,
          studyPermit: upload7 || admissionData.upload?.studyPermit,
          studyPermitName:
            values?.studyPermit?.name || admissionData.upload?.studyPermitName,
          workPermit: upload8 || admissionData.upload?.workPermit,
          workPermitName:
            values?.workPermit?.name || admissionData.upload?.workPermitName,
          photoId: upload9 || admissionData.upload?.photoId,
          photoIdName:
            values?.photoId?.name || admissionData.upload?.photoIdName,
          socialInsuranceNumber:
            upload10 || admissionData.upload?.socialInsuranceNumber,
          socialInsuranceNumberName:
            values?.socialInsuranceNumber?.name ||
            admissionData.upload?.socialInsuranceNumberName,
          noticeOfAssessment:
            upload11 || admissionData.upload?.noticeOfAssessment,
          noticeOfAssessmentName:
            values?.noticeOfAssessment?.name ||
            admissionData.upload?.noticeOfAssessmentName,
          declarationForm: upload12 || admissionData.upload?.declarationForm,
          declarationFormName:
            values?.declarationForm?.name ||
            admissionData.upload?.declarationFormName,
          fileId: admissionData?.upload?.fileId,
          isRejected: false,
        },
      },
    };
    try {
      const response = await axiosService.post(
        `${ADMISSION_URL_FOR_STUDENT.ADMISSION_URL}`,
        saveData
      );

      if (response.status === 200) {
        toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.LIST_FILE_SUCCESS);
        navigation("/pre-onboarding/create-student-profile", {
          state: {
            data: programId,
          },
        });
      } else {
        toast.error(
          ADMISSION_CONSTANTS_FOR_STUDENTS.LIST_FILE_SUCCESS_FILE_FAILURE
        );
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.LIST_FILE_ERROR);
      }
    }
  };

  const dateString = new Date(admissionData?.upload?.updatedOn);
  const formattedDate =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString.toLocaleDateString()
      : "Invalid Date";

  const handleFileDropEncrypted = async (
    fieldName,
    acceptedFiles,
    setFieldValue
  ) => {
    const file = acceptedFiles[0];
    if (acceptedFiles[0]?.name?.length > 150) {
      toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.IMAGE_LENGTH);
      return;
    }
    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    const maxFileSize = 2 * 1024 * 1024;

    if (
      file &&
      allowedFileTypes.includes(file.type) &&
      file.size <= maxFileSize
    ) {
      try {
        if (fieldName === "degree") {
          setIsUploading(true);
        } else if (fieldName === "passportSizePhoto") {
          setIsUploading2(true);
        } else if (fieldName === "englishExamResults") {
          setIsUploading3(true);
        } else if (fieldName === "passport") {
          setIsUploading4(true);
        } else if (fieldName === "wonderlicTest") {
          setIsUploading5(true);
        } else if (fieldName === "cv") {
          setIsUploading6(true);
        } else if (fieldName === "studyPermit") {
          setIsUploading7(true);
        } else if (fieldName === "workPermit") {
          setIsUploading8(true);
        } else if (fieldName === "photoId") {
          setIsUploading9(true);
        } else if (fieldName === "socialInsuranceNumber") {
          setIsUploading10(true);
        } else if (fieldName === "noticeOfAssessment") {
          setIsUploading11(true);
        } else if (fieldName === "declarationForm") {
          setIsUploading12(true);
        }
        let hashValueData = sha256(`${fieldName}-${userData?.userId}`)
          .toString()
          .substring(0, 20);

        console.log(hashValueData, "hashValueData");

        const formData = new FormData();
        formData.append("file", acceptedFiles);
        const response = await axiosService.postFile(
          // `${ADMISSION_URL_FOR_STUDENT.FILE_UPLOAD_URL}?hashValue=${hashValueData}&`,
          `${DOCUMENT_URLS.ENCRYPTED_MINIO_UPLOAD}?hashValue=${hashValueData}&userId=${userData?.userId}&userUpload=${fieldName}`,
          file,
          acceptedFiles
        );
        if (response.status === 200) {
          toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_SUCCESS);
          const data = response?.data?.data?.fileUrl;
          console.log(response.data, "resppppp");
          setFieldValue(fieldName, file);
          if (fieldName === "degree") {
            setIsUploading(false);
            setUpload1(data);
          } else if (fieldName === "passportSizePhoto") {
            setIsUploading2(false);
            setUpload2(data);
          } else if (fieldName === "englishExamResults") {
            setIsUploading3(false);
            setUpload3(data);
          } else if (fieldName === "passport") {
            setIsUploading4(false);
            setUpload4(data);
          } else if (fieldName === "wonderlicTest") {
            setIsUploading5(false);
            setUpload5(data);
          } else if (fieldName === "cv") {
            setIsUploading6(false);
            setUpload6(data);
          } else if (fieldName === "studyPermit") {
            setIsUploading7(false);
            setUpload7(data);
          } else if (fieldName === "workPermit") {
            setIsUploading8(false);
            setUpload8(data);
          } else if (fieldName === "photoId") {
            setIsUploading9(false);
            setUpload9(data);
          } else if (fieldName === "socialInsuranceNumber") {
            setIsUploading10(false);
            setUpload10(data);
          } else if (fieldName === "noticeOfAssessment") {
            setIsUploading11(false);
            setUpload11(data);
          } else if (fieldName === "declarationForm") {
            setIsUploading12(false);
            setUpload12(data);
          }
        } else {
          toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_FAILURE);
        }
      } catch (error) {
        if (fieldName === "degree") {
          setIsUploading(false);
        } else if (fieldName === "passportSizePhoto") {
          setIsUploading2(false);
        } else if (fieldName === "englishExamResults") {
          setIsUploading3(false);
        } else if (fieldName === "passport") {
          setIsUploading4(false);
        } else if (fieldName === "wonderlicTest") {
          setIsUploading5(false);
        } else if (fieldName === "cv") {
          setIsUploading6(false);
        } else if (fieldName === "studyPermit") {
          setIsUploading7(false);
        } else if (fieldName === "workPermit") {
          setIsUploading8(false);
        } else if (fieldName === "photoId") {
          setIsUploading9(false);
        } else if (fieldName === "socialInsuranceNumber") {
          setIsUploading10(false);
        } else if (fieldName === "noticeOfAssessment") {
          setIsUploading11(false);
        } else if (fieldName === "declarationForm") {
          setIsUploading12(false);
        }

        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_ERROR);
        }
      }
    } else {
      if (!allowedFileTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload only .png, .jpeg, .jpg, or .pdf files."
        );
      } else if (file.size > maxFileSize) {
        toast.error("File size exceeds the limit of 2MB.");
      }
    }
  };

  const handleFileDrop = async (fieldName, acceptedFiles, setFieldValue) => {
    const file = acceptedFiles[0];
    if (acceptedFiles[0]?.name?.length > 150) {
      toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.IMAGE_LENGTH);
      return;
    }
    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    const maxFileSize = 2 * 1024 * 1024;

    if (
      file &&
      allowedFileTypes.includes(file.type) &&
      file.size <= maxFileSize
    ) {
      try {
        if (fieldName === "degree") {
          setIsUploading(true);
        } else if (fieldName === "passportSizePhoto") {
          setIsUploading2(true);
        } else if (fieldName === "englishExamResults") {
          setIsUploading3(true);
        } else if (fieldName === "passport") {
          setIsUploading4(true);
        } else if (fieldName === "wonderlicTest") {
          setIsUploading5(true);
        } else if (fieldName === "cv") {
          setIsUploading6(true);
        } else if (fieldName === "studyPermit") {
          setIsUploading7(true);
        } else if (fieldName === "workPermit") {
          setIsUploading8(true);
        } else if (fieldName === "photoId") {
          setIsUploading9(true);
        } else if (fieldName === "socialInsuranceNumber") {
          setIsUploading10(true);
        } else if (fieldName === "noticeOfAssessment") {
          setIsUploading11(true);
        } else if (fieldName === "declarationForm") {
          setIsUploading12(true);
        }

        const formData = new FormData();
        formData.append("file", acceptedFiles);
        const response = await axiosService.postFile(
          `${ADMISSION_URL_FOR_STUDENT.FILE_UPLOAD_URL}`,
          file,
          acceptedFiles
        );
        if (response.status === 200) {
          toast.success(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_SUCCESS);
          const data = response.data.data;
          setFieldValue(fieldName, file);
          if (fieldName === "degree") {
            setIsUploading(false);
            setUpload1(data);
          } else if (fieldName === "passportSizePhoto") {
            setIsUploading2(false);
            setUpload2(data);
          } else if (fieldName === "englishExamResults") {
            setIsUploading3(false);
            setUpload3(data);
          } else if (fieldName === "passport") {
            setIsUploading4(false);
            setUpload4(data);
          } else if (fieldName === "wonderlicTest") {
            setIsUploading5(false);
            setUpload5(data);
          } else if (fieldName === "cv") {
            setIsUploading6(false);
            setUpload6(data);
          } else if (fieldName === "studyPermit") {
            setIsUploading7(false);
            setUpload7(data);
          } else if (fieldName === "workPermit") {
            setIsUploading8(false);
            setUpload8(data);
          } else if (fieldName === "photoId") {
            setIsUploading9(false);
            setUpload9(data);
          } else if (fieldName === "socialInsuranceNumber") {
            setIsUploading10(false);
            setUpload10(data);
          } else if (fieldName === "noticeOfAssessment") {
            setIsUploading11(false);
            setUpload11(data);
          } else if (fieldName === "declarationForm") {
            setIsUploading12(false);
            setUpload12(data);
          }
        } else {
          toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_FAILURE);
        }
      } catch (error) {
        if (fieldName === "degree") {
          setIsUploading(false);
        } else if (fieldName === "passportSizePhoto") {
          setIsUploading2(false);
        } else if (fieldName === "englishExamResults") {
          setIsUploading3(false);
        } else if (fieldName === "passport") {
          setIsUploading4(false);
        } else if (fieldName === "wonderlicTest") {
          setIsUploading5(false);
        } else if (fieldName === "cv") {
          setIsUploading6(false);
        } else if (fieldName === "studyPermit") {
          setIsUploading7(false);
        } else if (fieldName === "workPermit") {
          setIsUploading8(false);
        } else if (fieldName === "photoId") {
          setIsUploading9(false);
        } else if (fieldName === "socialInsuranceNumber") {
          setIsUploading10(false);
        } else if (fieldName === "noticeOfAssessment") {
          setIsUploading11(false);
        } else if (fieldName === "declarationForm") {
          setIsUploading12(false);
        }

        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
          toast.error(ADMISSION_CONSTANTS_FOR_STUDENTS.UPLOAD_FILE_ERROR);
        }
      }
    } else {
      if (!allowedFileTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload only .png, .jpeg, .jpg, or .pdf files."
        );
      } else if (file.size > maxFileSize) {
        toast.error("File size exceeds the limit of 2MB.");
      }
    }
  };

  return (
    <Formik
      initialValues={admissionData?.upload || initialValues}
      // validationSchema={validationSchema}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ setFieldValue, values, touched, errors }) => (
        <Form>
          <div className="create-admission-main-container ">
            <div className="progress-container">
              <div className="create-admission-head-text">Registration</div>
              <div className="col">
                <ProgressBar
                  now={admissionData?.pagePercentage || 0}
                  label={`${admissionData?.pagePercentage}%`}
                />
              </div>
            </div>
            <div className="row create-admission-container g-0">
              <div className="create-admission-inner-left-container col-12 col-lg-3 pt-2">
                <AdmissionSideBar />
              </div>

              <div className="create-admission-inner-right-container col-12 col-lg-9 py-2 ">
                <div className="create-text-title ">
                  List of Uploaded files.
                  <p className="para-existing-text">
                    Please click choose file to upload new file or replace
                    existing file..
                  </p>
                  <div className="upload-files-note">
                    (Note: Only files in the formats .png, .jpeg, .jpg, and .pdf
                    are supported. The maximum supported file size is 2MB). To
                    compress the file size
                    <a
                      style={{ textDecoration: "underline" }}
                      href="https://www.ilovepdf.com/"
                      target="_blank"
                    >
                      {" "}
                      https://www.ilovepdf.com/
                    </a>
                  </div>
                </div>

                <div>
                  <div className="create-list-upload-main-input-container">
                    <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="upload1"
                        >
                          Highest Qualification Passing Certificate *
                        </label>
                        <Dropzone
                          onDrop={async (acceptedFiles) => {
                            setUpload1(null);
                            handleFileDrop(
                              "degree",
                              acceptedFiles,
                              setFieldValue
                            );
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                              {...getRootProps()}
                              className={`create-list-upload-input-big ${
                                isDragActive ? "drag-active" : ""
                              }`}
                            >
                              <input
                                {...getInputProps()}
                                className="d-none"
                                type="file"
                                id="upload1"
                                name="degree"
                              />

                              {values.degree?.name ||
                              admissionData?.upload?.degreeName ? (
                                <span className="text-white">
                                  {values.degree?.name ||
                                    admissionData?.upload?.degreeName}
                                </span>
                              ) : (
                                "No file chosen"
                              )}
                              <div className="create-list-upload-input-choose">
                                Choose File
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>

                      {(admissionData?.upload?.degree || upload1) &&
                      !isUploading ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {isUploading && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {(upload1 || admissionData?.upload?.degree) && (
                        <a
                          href={upload1 || admissionData?.upload?.degree}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                      {touched && touched.degree && errors && errors.degree && (
                        <div className="error-message mob error highqualification">
                          {errors.degree}
                        </div>
                      )}
                    </div>

                    <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="upload2"
                        >
                          Latest Color Passport Size Photo *
                        </label>
                        <Dropzone
                          onDrop={async (acceptedFiles) => {
                            setUpload2(null);
                            handleFileDrop(
                              "passportSizePhoto",
                              acceptedFiles,
                              setFieldValue
                            );
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                              {...getRootProps()}
                              className={`create-list-upload-input-big ${
                                isDragActive ? "drag-active" : ""
                              }`}
                            >
                              <input
                                {...getInputProps()}
                                className="d-none"
                                type="file"
                                id="upload2"
                                name="passportSizePhoto"
                              />

                              {values.passportSizePhoto?.name ||
                              admissionData?.upload?.passportSizePhotoName ? (
                                <span className="text-white">
                                  {values.passportSizePhoto?.name ||
                                    admissionData?.upload
                                      ?.passportSizePhotoName}
                                </span>
                              ) : (
                                "No file chosen"
                              )}
                              <div className="create-list-upload-input-choose">
                                Choose File
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {touched &&
                        touched.passportSizePhoto &&
                        errors &&
                        errors.passportSizePhoto && (
                          <div className="error-message mob error passportSizePhoto">
                            {errors.passportSizePhoto}
                          </div>
                        )}
                      {(values.passportSizePhoto || upload2) &&
                      !isUploading2 ? (
                        <BiCheck color="green" size={25} />
                      ) : null}
                      {isUploading2 && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {(upload2 ||
                        admissionData?.upload?.passportSizePhoto) && (
                        <a
                          href={
                            upload2 || admissionData?.upload?.passportSizePhoto
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>
                    {studentType !== "OSAP" && (
                      <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload3"
                          >
                            English Proficiency Test Scorecard * <br />
                            (IELTS/TOFEL/PTE)
                          </label>
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              setUpload3(null);
                              handleFileDrop(
                                "englishExamResults",
                                acceptedFiles,
                                setFieldValue
                              );
                            }}
                            accept="image/*, application/pdf"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                            }) => (
                              <div
                                {...getRootProps()}
                                className={`create-list-upload-input-big ${
                                  isDragActive ? "drag-active" : ""
                                }`}
                              >
                                <input
                                  {...getInputProps()}
                                  className="d-none"
                                  type="file"
                                  id="upload3"
                                  name="englishExamResults"
                                />

                                {values.englishExamResults?.name ||
                                admissionData?.upload
                                  ?.englishExamResultsName ? (
                                  <span className="text-white">
                                    {values.englishExamResults?.name ||
                                      admissionData?.upload
                                        ?.englishExamResultsName}
                                  </span>
                                ) : (
                                  "No file chosen"
                                )}
                                <div className="create-list-upload-input-choose">
                                  Choose File
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {touched &&
                          touched.englishExamResults &&
                          errors &&
                          errors.englishExamResults && (
                            <div className="error-message mob error englishexamresult">
                              {errors.englishExamResults}
                            </div>
                          )}
                        {(values.englishExamResults || upload3) &&
                        !isUploading3 ? (
                          <BiCheck color="green" size={25} />
                        ) : null}
                        {isUploading3 && (
                          <div className="lds-ring lds-ring-uploadpage">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        {(upload3 ||
                          admissionData?.upload?.englishExamResults) && (
                          <a
                            href={
                              upload3 ||
                              admissionData?.upload?.englishExamResults
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}
                    <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="upload4"
                        >
                          Valid Passport *
                        </label>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            setUpload4(null);
                            handleFileDropEncrypted(
                              "passport",
                              acceptedFiles,
                              setFieldValue
                            );
                            // handleFileDrop(
                            //   "passport",
                            //   acceptedFiles,
                            //   setFieldValue
                            // );
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                              {...getRootProps()}
                              className={`create-list-upload-input-big ${
                                isDragActive ? "drag-active" : ""
                              }`}
                            >
                              <input
                                {...getInputProps()}
                                className="d-none"
                                type="file"
                                id="upload4"
                                name="passport"
                              />

                              {values.passport?.name ||
                              admissionData?.upload?.passportName ? (
                                <span className="text-white">
                                  {values.passport?.name ||
                                    admissionData?.upload?.passportName}
                                </span>
                              ) : (
                                "No file chosen"
                              )}

                              <div className="create-list-upload-input-choose">
                                Choose File
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {touched &&
                        touched.passport &&
                        errors &&
                        errors.passport && (
                          <div className="error-message mob error listuploadPassport">
                            {errors.passport}
                          </div>
                        )}
                      {(admissionData?.upload?.passport || upload4) &&
                      !isUploading4 ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {isUploading4 && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {/* {(upload4 || admissionData?.upload?.passport) && (
                        <a
                          href={upload4 || admissionData?.upload?.passport}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )} */}
                    </div>
                    {studentType === "OSAP" && (
                      <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload9"
                          >
                            Photo ID *
                          </label>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleFileDropEncrypted(
                                "photoId",
                                acceptedFiles,
                                setFieldValue
                              )
                            }
                            accept="image/*, application/pdf"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                            }) => (
                              <div
                                {...getRootProps()}
                                className={`create-list-upload-input-big ${
                                  isDragActive ? "drag-active" : ""
                                }`}
                              >
                                <input
                                  {...getInputProps()}
                                  className="d-none"
                                  type="file"
                                  id="upload9"
                                  name="workPermit"
                                />

                                {values.photoId?.name ||
                                admissionData?.upload?.photoIdName ? (
                                  <span className="text-white">
                                    {values.photoId?.name ||
                                      admissionData?.upload?.photoIdName}
                                  </span>
                                ) : (
                                  "No file chosen"
                                )}
                                <div className="create-list-upload-input-choose">
                                  Choose File
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {(values.photoId || upload9) && !isUploading9 ? (
                          <BiCheck color="green" size={25} />
                        ) : null}
                        {isUploading9 && (
                          <div className="lds-ring lds-ring-uploadpage">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                        {/* {(upload9 || admissionData?.upload?.photoId) && (
                          <a
                            href={upload9 || admissionData?.upload?.photoId}
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )} */}
                        {touched &&
                          touched.photoId &&
                          errors &&
                          errors.photoId && (
                            <div className="error-message mob error highqualification">
                              {errors.photoId}
                            </div>
                          )}
                      </div>
                    )}
                    {studentType === "OSAP" && (
                      <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload12"
                          >
                            Declaration Form *
                          </label>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleFileDrop(
                                "declarationForm",
                                acceptedFiles,
                                setFieldValue
                              )
                            }
                            accept="image/*, application/pdf"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                            }) => (
                              <div
                                {...getRootProps()}
                                className={`create-list-upload-input-big ${
                                  isDragActive ? "drag-active" : ""
                                }`}
                              >
                                <input
                                  {...getInputProps()}
                                  className="d-none"
                                  type="file"
                                  id="upload12"
                                  name="declarationForm"
                                />

                                {values.declarationForm?.name ||
                                admissionData?.upload?.declarationFormName ? (
                                  <span className="text-white">
                                    {values.declarationForm?.name ||
                                      admissionData?.upload
                                        ?.declarationFormName}
                                  </span>
                                ) : (
                                  "No file chosen"
                                )}
                                <div className="create-list-upload-input-choose">
                                  Choose File
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {(values.declarationForm || upload12) &&
                        !isUploading12 ? (
                          <BiCheck color="green" size={25} />
                        ) : null}
                        {isUploading12 && (
                          <div className="lds-ring lds-ring-uploadpage">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                        {(upload12 ||
                          admissionData?.upload?.declarationForm) && (
                          <a
                            href={
                              upload12 || admissionData?.upload?.declarationForm
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                        {touched &&
                          touched.declarationForm &&
                          errors &&
                          errors.declarationForm && (
                            <div className="error-message mob error highqualification">
                              {errors.declarationForm}
                            </div>
                          )}
                      </div>
                    )}

                    <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="upload5"
                        >
                          Wonderlic Test
                        </label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleFileDrop(
                              "wonderlicTest",
                              acceptedFiles,
                              setFieldValue
                            )
                          }
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                              {...getRootProps()}
                              className={`create-list-upload-input-big ${
                                isDragActive ? "drag-active" : ""
                              }`}
                            >
                              <input
                                {...getInputProps()}
                                className="d-none"
                                type="file"
                                id="upload5"
                                name="wonderlicTest"
                              />

                              {values.wonderlicTest?.name ||
                              admissionData?.upload?.wonderlicTestName ? (
                                <span className="text-white">
                                  {values.wonderlicTest?.name ||
                                    admissionData?.upload?.wonderlicTestName}
                                </span>
                              ) : (
                                "No file chosen"
                              )}
                              <div className="create-list-upload-input-choose">
                                Choose File
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {(values.wonderlicTest || upload5) && !isUploading5 ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {isUploading5 && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}

                      {(upload5 || admissionData?.upload?.wonderlicTest) && (
                        <a
                          href={upload5 || admissionData?.upload?.wonderlicTest}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>

                    <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                      <div className="column d-flex">
                        <label
                          className="label-text-active label-width-upload-files"
                          htmlFor="upload6"
                        >
                          CV/Resume
                        </label>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            setUpload6(null);
                            handleFileDrop("cv", acceptedFiles, setFieldValue);
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps, isDragActive }) => (
                            <div
                              {...getRootProps()}
                              className={`create-list-upload-input-big ${
                                isDragActive ? "drag-active" : ""
                              }`}
                            >
                              <input
                                {...getInputProps()}
                                className="d-none"
                                type="file"
                                id="upload6"
                                name="cv"
                              />

                              {values.cv?.name ||
                              admissionData?.upload?.cvName ? (
                                <span className="text-white">
                                  {values.cv?.name ||
                                    admissionData?.upload?.cvName}
                                </span>
                              ) : (
                                "No file chosen"
                              )}
                              <div className="create-list-upload-input-choose">
                                Choose File
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>

                      {(values.cv || upload6) && !isUploading6 ? (
                        <BiCheck color="green" size={25} />
                      ) : null}
                      {isUploading6 && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}

                      {(upload6 || admissionData?.upload?.cv) && (
                        <a
                          href={upload6 || admissionData?.upload?.cv}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye />
                        </a>
                      )}
                    </div>
                    {studentType !== "OSAP" && (
                      <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload7"
                          >
                            Study Permit
                          </label>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleFileDropEncrypted(
                                "studyPermit",
                                acceptedFiles,
                                setFieldValue
                              )
                            }
                            accept="image/*, application/pdf"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                            }) => (
                              <div
                                {...getRootProps()}
                                className={`create-list-upload-input-big ${
                                  isDragActive ? "drag-active" : ""
                                }`}
                              >
                                <input
                                  {...getInputProps()}
                                  className="d-none"
                                  type="file"
                                  id="upload7"
                                  name="studyPermit"
                                />

                                {values.studyPermit?.name ||
                                admissionData?.upload?.studyPermitName ? (
                                  <span className="text-white">
                                    {values.studyPermit?.name ||
                                      admissionData?.upload?.studyPermitName}
                                  </span>
                                ) : (
                                  "No file chosen"
                                )}
                                <div className="create-list-upload-input-choose">
                                  Choose File
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {(values.studyPermit || upload7) && !isUploading7 ? (
                          <BiCheck color="green" size={25} />
                        ) : null}
                        {isUploading7 && (
                          <div className="lds-ring lds-ring-uploadpage">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                        {/* {(upload7 || admissionData?.upload?.studyPermit) && (
                          <a
                            href={upload7 || admissionData?.upload?.studyPermit}
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )} */}
                      </div>
                    )}
                    {studentType !== "OSAP" && (
                      <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload8"
                          >
                            Work Permit
                          </label>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleFileDropEncrypted(
                                "workPermit",
                                acceptedFiles,
                                setFieldValue
                              )
                            }
                            accept="image/*, application/pdf"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                            }) => (
                              <div
                                {...getRootProps()}
                                className={`create-list-upload-input-big ${
                                  isDragActive ? "drag-active" : ""
                                }`}
                              >
                                <input
                                  {...getInputProps()}
                                  className="d-none"
                                  type="file"
                                  id="upload8"
                                  name="workPermit"
                                />

                                {values.workPermit?.name ||
                                admissionData?.upload?.workPermitName ? (
                                  <span className="text-white">
                                    {values.workPermit?.name ||
                                      admissionData?.upload?.workPermitName}
                                  </span>
                                ) : (
                                  "No file chosen"
                                )}
                                <div className="create-list-upload-input-choose">
                                  Choose File
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {(values.workPermit || upload8) && !isUploading8 ? (
                          <BiCheck color="green" size={25} />
                        ) : null}
                        {isUploading8 && (
                          <div className="lds-ring lds-ring-uploadpage">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                        {/* {(upload8 || admissionData?.upload?.workPermit) && (
                          <a
                            href={upload8 || admissionData?.upload?.workPermit}
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )} */}
                      </div>
                    )}

                    {studentType === "OSAP" && (
                      <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload10"
                          >
                            Social Insurance Number (SIN)
                          </label>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleFileDropEncrypted(
                                "socialInsuranceNumber",
                                acceptedFiles,
                                setFieldValue
                              )
                            }
                            accept="image/*, application/pdf"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                            }) => (
                              <div
                                {...getRootProps()}
                                className={`create-list-upload-input-big ${
                                  isDragActive ? "drag-active" : ""
                                }`}
                              >
                                <input
                                  {...getInputProps()}
                                  className="d-none"
                                  type="file"
                                  id="upload10"
                                  name="workPermit"
                                />

                                {values.socialInsuranceNumber?.name ||
                                admissionData?.upload
                                  ?.socialInsuranceNumberName ? (
                                  <span className="text-white">
                                    {values.socialInsuranceNumber?.name ||
                                      admissionData?.upload
                                        ?.socialInsuranceNumberName}
                                  </span>
                                ) : (
                                  "No file chosen"
                                )}
                                <div className="create-list-upload-input-choose">
                                  Choose File
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {(values.socialInsuranceNumber || upload10) &&
                        !isUploading10 ? (
                          <BiCheck color="green" size={25} />
                        ) : null}
                        {isUploading10 && (
                          <div className="lds-ring lds-ring-uploadpage">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                        {(upload10 ||
                          admissionData?.upload?.socialInsuranceNumber) && (
                          <a
                            href={
                              upload10 ||
                              admissionData?.upload?.socialInsuranceNumber
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}
                    {studentType === "OSAP" && (
                      <div className="create-list-upload-inner-input-container mb-4 m-lg-3">
                        <div className="column d-flex">
                          <label
                            className="label-text-active label-width-upload-files"
                            htmlFor="upload11"
                          >
                            Notice Of Assessment (NOA)
                          </label>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleFileDrop(
                                "noticeOfAssessment",
                                acceptedFiles,
                                setFieldValue
                              )
                            }
                            accept="image/*, application/pdf"
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive,
                            }) => (
                              <div
                                {...getRootProps()}
                                className={`create-list-upload-input-big ${
                                  isDragActive ? "drag-active" : ""
                                }`}
                              >
                                <input
                                  {...getInputProps()}
                                  className="d-none"
                                  type="file"
                                  id="upload11"
                                  name="workPermit"
                                />

                                {values.noticeOfAssessment?.name ||
                                admissionData?.upload
                                  ?.noticeOfAssessmentName ? (
                                  <span className="text-white">
                                    {values.noticeOfAssessment?.name ||
                                      admissionData?.upload
                                        ?.noticeOfAssessmentName}
                                  </span>
                                ) : (
                                  "No file chosen"
                                )}
                                <div className="create-list-upload-input-choose">
                                  Choose File
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {(values.noticeOfAssessment || upload11) &&
                        !isUploading11 ? (
                          <BiCheck color="green" size={25} />
                        ) : null}
                        {isUploading11 && (
                          <div className="lds-ring lds-ring-uploadpage">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                        {(upload11 ||
                          admissionData?.upload?.noticeOfAssessment) && (
                          <a
                            href={
                              upload11 ||
                              admissionData?.upload?.noticeOfAssessment
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="view-eye-icon"
                          >
                            <FaEye />
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex gap-3 py-3 flex-wrap">
                  <button
                    type="button"
                    className="create-admission-previous-button"
                    onClick={navigateToUploadFiles}
                  >
                    Previous
                  </button>
                  {!admissionData?.upload?.fileId ? (
                    <button
                      type="submit"
                      className="create-admission-save-button"
                      // disabled={formik.isSubmitting}
                    >
                      Save & Next
                    </button>
                  ) : (
                    (rejected || (!rejected && !approved && !isFeeData)) && (
                      <button
                        type="submit"
                        className="create-admission-save-button"
                        // disabled={formik.isSubmitting}
                      >
                        Edit & Save
                      </button>
                    )
                  )}

                  {(admissionData?.upload?.fileId ||
                    admissionData?.upload?.isApproved) && (
                    <button
                      type="button"
                      className=" create-admission-next-button"
                      onClick={() =>
                        navigation("/pre-onboarding/create-student-profile", {
                          state: {
                            data: programId,
                          },
                        })
                      }
                    >
                      Next
                    </button>
                  )}
                </div>
                {admissionData?.upload?.isApproved && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <span className="approve-textarea">
                      <IoIosCheckmarkCircle className="admission-approve-check-icon" />
                      Approved
                    </span>
                    <span className="approve-date-text">
                      on {formattedDate}
                    </span>
                  </div>
                )}
                {admissionData?.upload?.isRejected && (
                  <div className="row approve-reject-comments-container">
                    <span className="admission-comments-head-text">
                      Comments
                    </span>
                    <div className="rejected-container p-0 mt-2">
                      <div className="rejcted-head-text">
                        <RiCloseCircleFill className="admission-reject-icon" />
                        Rejected
                      </div>
                      <div className="inner-reject-text">
                        {admissionData?.upload?.rejectComments}
                      </div>
                    </div>
                    <span className="approve-date-text">{formattedDate}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CreateListUploadsFiles;
