import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FacultyAttendance from "../attendance-faculty/FacultyAttendance";
import AttendanceFaculty from "../attendance-faculty/MyAttendanceFaculty";
import MyClasses from "../attendance-faculty/MyClasses";
import StudentAttendanceFaculty from "../attendance-faculty/StudentFacultyAttendance";
import ClassAttendance from "../attendance-faculty/ClassAttendance";
import MarkAttendance from "../attendance-faculty/MarkAttendance";
import ViewAttendance from "../attendance-faculty/ViewAttendance";
import sessionService from "services/sessionstorage.service";

function AttendanceManagementRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = sessionService.getUserData();
  const role = userData?.role?.name;

  useEffect(() => {
    if (location.pathname === "/attendance-management") {
      if (role === "student") {
        navigate("/attendance-management/my-attendance-student");
      } else if (role === "super admin") {
        navigate("/attendance-management/faculty-attendance");
      } else if (role === "faculty") {
        navigate("/attendance-management/my-classes");
      } else if (role === "office-staff") {
        navigate("/attendance-management/list-class");
      }
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="my-attendance-faculty" element={<AttendanceFaculty />} />
      <Route
        path="my-attendance-student"
        element={<StudentAttendanceFaculty />}
      />
      <Route path="faculty-attendance" element={<FacultyAttendance />} />
      <Route path="my-classes" element={<MyClasses />} />
      <Route path="mark-attendance" element={<MarkAttendance />} />
      <Route path="view-attendance" element={<ViewAttendance />} />
      <Route path="list-class" element={<ClassAttendance />} />
    </Routes>
  );
}

export default AttendanceManagementRoutes;
