import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./AdminCalendar.css";
import sessionService from "services/sessionstorage.service";
import { axiosService } from "services/axios.service";
import { TIMETABLE_URLS } from "modules/timetable-management/constants/timetable-constants-url";
import { useLocation } from "react-router-dom";
import { PROGRAM_URLS } from "modules/academic-management/constants/program-constants-urls";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import MeetingLinkPopup from "../meeting-link-popup/MeetingLinkPopup";
import { USER_URL } from "modules/user-management/constants/user-urls";

const AdminCalendar = () => {
  const location = useLocation();
  const userData = sessionService.getUserData("userData");

  const userId = userData?.userId;
  const [calendarData, setCalendarData] = useState([]);
  const [MeetingLinkPopUp, setMeetingLinkToPopUp] = useState(false);
  const [meetingLink, setMeetingLink] = useState("");

  const [facultyOptions, setFacultyOptions] = useState([]);

  const [faculty, setFaculty] = useState({
    value: "",
    label: "Select",
  });

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      height: "32px !important",
      paddingRight: "10px",
      width: "100% !important",
      minWidth: "150px !important",
      backgroundColor: "#f69f3a !important",
      borderRadius: "6px !important",
      color: "#212226 !important",
      boxShadow: "none !important",
    }),
  };

  const convertTo24HourFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period === "AM" && hours === 12) {
      hours = 0;
    } else if (period === "PM" && hours !== 12) {
      hours += 12;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  const transformedEventsData = calendarData.map((event) => {
    const startTimeEvent = convertTo24HourFormat(event.startTime);
    const endTimeEvent = convertTo24HourFormat(event.endTime);

    const startTime = new Date(`${event.startDate}T${startTimeEvent}`);
    const endTime = new Date(`${event.endDate}T${endTimeEvent}`);
    const program = event.module.programFeeMaster.program;

    return {
      title: event?.module?.module?.moduleName,
      start: startTime,
      end: endTime,
      stime: event.startTime,
      etime: event.endTime,
      description: event?.description,
      // url: "https://www.366icons.com/media/01/profile-avatar-account-icon-16699.png",
      extendedProps: {
        program: program,
      },
    };
  });
  const [programOptions, setProgramOptions] = useState([]);
  const [programName, setProgramName] = useState({
    value: "",
    label: "ALL",
  });

  const [program, setProgram] = useState("");

  useEffect(() => {
    axiosService.get(`${PROGRAM_URLS.GET_ALL_PROGRAMS}`).then((response) => {
      if (response.status === 200) {
        const responseData = response.data.data?.programModel;
        const programOptions = responseData?.map((program) => ({
          value: program.master.program,
          programFeeMasterId: program.master.programFeeMasterId,
          label: program.master.program,
        }));
        const allOption = { value: "", label: "ALL" };
        const updatedOptions = [allOption, ...programOptions];

        setProgramOptions(updatedOptions);
      }
    });
  }, [location.pathname]);
  const [semsterOptions, setSemesterOptions] = useState([]);
  const [semesterCount, setSemesterCount] = useState({
    value: "",
    label: "ALL",
  });
  const getSemesterCount = (programId) => {
    axiosService
      .get(`${TIMETABLE_URLS.GET_SEMESTER_PROGRAMFEEMASTER_ID}${programId}`)
      .then((response) => {
        const data = response?.data?.data;

        const semesterOptions = data?.map((semester) => ({
          value: semester,
          label: semester,
        }));
        const allOption = { value: "", label: "ALL" };
        const updatedOptions = [allOption, ...semesterOptions];

        setSemesterOptions(updatedOptions);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (faculty?.value) {
      let url = `${TIMETABLE_URLS.GET_FACULTY_CALENDAR_BY_USERID}?userId=${faculty?.value}`;

      if (semesterCount.value) {
        url += `&semester=${semesterCount.value}`;
      }

      if (programName.value) {
        url += `&program=${programName.value}`;
      }

      axiosService.get(url).then((response) => {
        if (response.status === 200) {
          const responseData = response?.data?.data;
          setCalendarData(responseData);
        }
      });
    }
  }, [
    userId,
    location.pathname,
    programName.value,
    semesterCount.value,
    faculty.value,
  ]);

  useEffect(() => {
    const roleName = "faculty";
    axiosService
      .get(`${USER_URL.GET_USER_BY_ROLENAME}?roleName=${roleName}`)
      .then((response) => {
        const data = response?.data?.data;
        console.log(data, "dataaa");

        const faculty_options = data?.map((a) => ({
          value: a.userId,
          label: a?.lastName ? `${a.firstName} ${a.lastName}` : a.firstName,
        }));
        setFacultyOptions(faculty_options);
      });
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header flex-row flex-wrap gap-2">
        <span className="lead-table-title-text">Calendar</span>

        <div className="d-flex align-items-center gap-2 flex-wrap">
          <div className="d-flex align-items-center">
            <span class="ag-header-cell-text">Faculty :</span>
            <Select
              id="Faculty"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  const programName = selectedOption;

                  setFaculty(programName);
                }
              }}
              value={faculty}
              options={facultyOptions}
              styles={selectStyles}
              isSearchable={true}
              classNamePrefix="react-select-att"
              components={{
                DropdownIndicator: () => <RxTriangleDown color="#000000" />,
                IndicatorSeparator: null,
              }}
              placeholder="Select"
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </div>
          <div className="d-flex align-items-center">
            <span class="ag-header-cell-text">Program :</span>
            <Select
              id="programName"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  const programName = selectedOption;
                  const programFeeMasterId = selectedOption.programFeeMasterId;

                  setProgramName(programName);
                  getSemesterCount(programFeeMasterId);
                  setSemesterCount("");
                }
              }}
              value={programName}
              options={programOptions}
              styles={selectStyles}
              isSearchable={true}
              classNamePrefix="react-select-att"
              components={{
                DropdownIndicator: () => <RxTriangleDown color="#000000" />,
                IndicatorSeparator: null,
              }}
              placeholder="Select"
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </div>

          <div className="d-flex align-items-center">
            <span class="ag-header-cell-text">Semester :</span>

            <Select
              id="semesterCount"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setSemesterCount(selectedOption);
                }
              }}
              value={semesterCount}
              options={semsterOptions}
              styles={selectStyles}
              isSearchable={true}
              classNamePrefix="react-select-att"
              components={{
                DropdownIndicator: () => <RxTriangleDown color="#000000" />,
                IndicatorSeparator: null,
              }}
              placeholder="Select"
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </div>
        </div>
      </div>

      <div className="Schedules-fullcalendar">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          themeSystem="standard"
          headerToolbar={{
            left: "prev,today,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek",
          }}
          dayMinWidth="10px"
          dragScroll="true"
          views={{
            dayGridMonth: {
              titleFormat: { month: "long" },
              showNonCurrentDates: false,
              eventDisplay: "block",
            },
            timeGrid: {
              titleFormat: { day: "2-digit", month: "long", year: "numeric" },
              columnHeaderFormat: {
                day: "numeric",
                month: "numeric",
                omitCommas: true,
              },
              dayHeaderFormat: {
                day: "numeric",
                month: "short",
                weekday: "long",
                omitCommas: true,
              },
              slotDuration: "00:30:00",
              slotLabelInterval: "00:30",
              slotLabelFormat: { hour: "2-digit", minute: "2-digit" },
            },
          }}
          events={transformedEventsData}
          eventContent={function (arg) {
            let para = document.createElement("p");
            let italicEl = document.createElement("i");
            let spanning = document.createElement("div");

            let startTime = new Date(arg.event.start).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            let endTime = new Date(arg.event.end).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            spanning.innerHTML = `${startTime} - ${endTime}`;
            para.innerHTML = arg.event.title;

            if (arg.event.extendedProps.isUrgent) {
              italicEl.innerHTML = "urgent event";
            } else {
              italicEl.innerHTML = "normal event";
            }

            let arrayOfDomNodes = [para, spanning];
            return { domNodes: arrayOfDomNodes };
          }}
          eventBorderColor="#F69F3A"
          eventBackgroundColor="#473929"
          weekends={false}
          eventClick={function (info) {
            info.jsEvent.preventDefault();
            setMeetingLinkToPopUp(true);
            setMeetingLink(info.event);
            setProgram(info.event.extendedProps.program);
            info.el.style.borderColor = "red";
          }}
        />
      </div>

      {MeetingLinkPopUp && (
        <MeetingLinkPopup
          open={MeetingLinkPopUp}
          onClose={() => {
            setMeetingLinkToPopUp(false);
          }}
          meetingLink={meetingLink}
          program={program}
          setMeetingLink={setMeetingLink}
        />
      )}
    </div>
  );
};

export default AdminCalendar;
