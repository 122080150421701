import { AppConfig } from "env.development";

export const TIMETABLE_URLS = {
  GET_ALL_RESOURCES: `${AppConfig.api_url}resource/v1/all`,
  ADD_RESOURCES: `${AppConfig.api_url}resource/v1`,
  GET_RESOURCE_BY_RESOURCE_ID: `${AppConfig.api_url}resource/v1`,
  GET_SCHEDULE_BY_SCHEDULE_ID: `${AppConfig.api_url}schedule/v1`,
  GET_FACULTY_CALENDAR_BY_USERID: `${AppConfig.api_url}schedule/v1/all/filter-faculty`,
  GET_STUDENT_CALENDAR_BY_USERID: `${AppConfig.api_url}schedule/v1/student1`,
  GET_SEMESTER_PROGRAMFEEMASTER_ID: `${AppConfig.api_url}program/v1/semester/`,
  GET_MODULE_BY_PROGRAMFEEMASTER_ID: `${AppConfig.api_url}program/v1/get-module/`,
  GET_ALL_FACULTIES: `${AppConfig.api_url}user/v1/getAllByRole`,
};
