import React, { useEffect, useState } from "react";
import "./HomeStudent.css";
import { TfiEmail } from "react-icons/tfi";
import { VscCallOutgoing } from "react-icons/vsc";
import LocationLogo from "assets/image-components/LocationLogo";
import BetaLogo from "assets/images/Beta CRM_files/Mask group (2).png";
import StudentIDVector from "assets/images/Beta CRM_files/studentIdCardVector1.png";
import { useNavigate } from "react-router-dom";
import useravatar from "assets/images/user-avatar.svg";
import { FaStar } from "react-icons/fa";
import { STUDENT_PORTAL_URL } from "modules/student-portal/constants/student-portal-urls";
import { axiosService } from "services/axios.service";
import sessionService from "services/sessionstorage.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProfileViewIcon from "assets/image-components/ProfileViewIcon";
import { IoIosMail } from "react-icons/io";
import { IoIosGlobe } from "react-icons/io";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FiPhoneCall } from "react-icons/fi";
import QRCode from "react-qr-code";
import { Dropdown } from "react-bootstrap";
import { DOCUMENTS_URL_FOR_STUDENT } from "modules/student-portal/components/student-portal-documents/constants/student-portal-documents-urls";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import FeesInvoice from "modules/documents/components/enrollment-letter/feesInvoice";
import FLOAForDocument from "modules/documents/components/enrollment-letter/floa";
import CLOAForDocument from "modules/documents/components/enrollment-letter/cloa";

const MyComponent = ({
  admissionData,
  triggerDownload,
  setTriggerDownload,
  profileImage,
}) => {
  useEffect(() => {
    if (triggerDownload === true) {
      generatePDF();
      setTriggerDownload(!triggerDownload);
    }
  }, [triggerDownload]);

  const generatePDF = async () => {
    const DPI = 96;

    const pxToMm = (1 / DPI) * 25.4;

    const htmlWidthPx = 592;
    const htmlHeightPx = 942;

    const widthMm = htmlWidthPx * pxToMm;
    const heightMm = htmlHeightPx * pxToMm;

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [widthMm, heightMm],
    });

    const scale = 1;
    doc.scale(scale);

    const toggleContentVisibility = (contentId, isVisible) => {
      const content = document.getElementById(contentId);
      if (isVisible) {
        content.classList.remove("student-id-card-hidden");
      } else {
        content.classList.add("student-id-card-hidden");
      }
    };

    const captureCanvas1Promise = new Promise((resolve, reject) => {
      toggleContentVisibility("content1", true);
      const content1 = document.getElementById("content1");
      const img = content1.querySelector("img");
      const options = {
        scale: scale,
        useCORS: true,
      };
      if (img.complete) {
        resolve(html2canvas(content1, options));
      } else {
        img.onload = () => resolve(html2canvas(content1, options));
        img.onerror = reject;
      }
    });

    const captureCanvas1 = await captureCanvas1Promise;

    const captureCanvas2Promise = new Promise((resolve, reject) => {
      toggleContentVisibility("content2", true);
      const content1 = document.getElementById("content2");
      const options = {
        scale: scale,
        useCORS: true,
      };
      if (content1) {
        resolve(html2canvas(content1, options));
      } else {
      }
    });

    const captureCanvas2 = await captureCanvas2Promise;

    toggleContentVisibility("content1", false);
    toggleContentVisibility("content2", false);

    Promise.all([captureCanvas1, captureCanvas2])
      .then((canvases) => {
        // Page 1
        const imgData1 = canvases[0].toDataURL("image/png");
        doc.addImage(imgData1, "JPEG", 0, 0);
        doc.addPage();

        // Page 2
        const imgData2 = canvases[1].toDataURL("image/png");
        doc.addImage(imgData2, "PNG", 0, 0);
        doc.save("example.pdf");
      })
      .catch((error) => {
        console.error("Error during PDF generation:", error);
      });
  };

  let value = `Student Name: "${admissionData?.admission?.personalData?.firstName} ${admissionData?.admission?.personalData?.lastName}",
Student ID: "${admissionData?.admission?.personalData?.studentId}",
Program: "${admissionData?.admission?.programDetails[0]?.program}",
Start Date : "${admissionData?.admission?.programDetails[0]?.fromDate}",
Expiry Date : "${admissionData?.admission?.programDetails[0]?.toDate}"`;

  return (
    <div>
      {/* <button onClick={generatePDF}>Generate PDF</button> */}
      <div
        id="content1"
        // className="student-id-card-hidden"
        style={{ width: "592px", height: "942px", marginTop: "50px" }}
      >
        <div className="student-id-card-orange-box">
          <div className="student-id-card-white-box"></div>
        </div>
        <div className="student-id-card-black-box"></div>

        <h1>STUDENT ID CARD</h1>
        <div
          style={{
            width: "436px",
            height: "525px",
            overflow: "hidden",
            border: "1px solid black",
            zIndex: "2",
          }}
          className="student-id-card-img-container"
        >
          <img
            src={profileImage}
            alt="Profile"
            height="100%"
            width="100%"
            style={{ objectFit: "cover", overflow: "hidden" }}
          />
          {/* <img
            src={URL.createObjectURL(imgBlob)} // Using the blob URL here
            alt="Student Profile" // Add an alt attribute for accessibility
          /> */}
        </div>
        <p
          style={{
            fontSize: "26px",
            fontWeight: "600",
            alignSelf: "flex-start",
          }}
          id="StudentID-content1-p1"
        >
          Student Name:{" "}
          <span style={{ fontWeight: "400" }}>
            {admissionData?.admission?.personalData?.firstName}{" "}
            {admissionData?.admission?.personalData?.lastName}
          </span>
        </p>
        <p
          style={{
            fontSize: "26px",
            fontWeight: "600",
            alignSelf: "flex-start",
          }}
          id="StudentID-content1-p2"
        >
          Student ID:{" "}
          <span style={{ fontWeight: "400" }}>
            {admissionData?.admission?.personalData?.studentId}
          </span>
        </p>
        <div
          style={{
            fontSize: "26px",
            fontWeight: "600",
            alignSelf: "flex-start",
          }}
          id="StudentID-content1-program"
        >
          <div style={{ color: "black", paddingRight: "5px" }}>Program: </div>
          <div style={{ color: "black", fontWeight: "400" }}>
            {admissionData?.admission?.programDetails[0]?.program}
          </div>
        </div>
        <img src={BetaLogo} alt="Beta Logo" id="student-Id-beta-logo" />
        <img src={StudentIDVector} alt="Profile" id="StudentIDVector1" />
        <img src={StudentIDVector} alt="Profile" id="StudentIDVector2" />
        <img src={StudentIDVector} alt="Profile" id="StudentIDVector3" />
        <img src={StudentIDVector} alt="Profile" id="StudentIDVector4" />
        <img src={StudentIDVector} alt="Profile" id="StudentIDVector5" />
      </div>
      <div
        id="content2"
        // className="student-id-card-hidden"
        style={{ width: "592px", height: "942px" }}
      >
        <div className="student-id-card2-div-1">
          <div className="student-id-card-content2-white-box-1"></div>
          <div className="student-id-card-content2-white-box-2"></div>
          <div className="student-id-card2-div-1-innerdiv1">
            <span
              style={{
                fontWeight: "900",
                fontSize: "27.05px",
                textDecoration: "underline",
              }}
            >
              MISSISSAUGA CAMPUS:
            </span>
            <br />
            4275 Village Center Court, Suite 201,
            <br />
            Mississauga, ON L4Z 1V3, Canada.
            <br />
            <span style={{ fontWeight: "900", fontSize: "25.55px" }}>
              <FiPhoneCall /> +1 905 848 5050
            </span>
          </div>
          <div className="student-id-card2-div-1-innerdiv2">
            <span
              style={{
                fontWeight: "900",
                fontSize: "27.05px",
                textDecoration: "underline",
              }}
            >
              BRAMPTON CAMPUS:
            </span>
            <br />
            1 Nelson Street West, Suite 200A,
            <br /> Brampton, ON L6X 3E4, Canada.
            <br />
            <span style={{ fontWeight: "900", fontSize: "25.55px" }}>
              <FiPhoneCall /> +1 905 497 9999
            </span>
          </div>
          <div className="student-id-card2-div-1-innerdiv3">
            <IoIosGlobe /> mybeta.ca
            <br />
            <IoIosMail /> hello@mybeta.ca
          </div>
        </div>
        <div className="student-id-card2-div-2">
          <div>
            Start Date :{" "}
            <span>{admissionData?.admission?.programDetails[0]?.fromDate}</span>
          </div>
          <div>
            Expiry Date :{" "}
            <span>{admissionData?.admission?.programDetails[0]?.toDate}</span>
          </div>
        </div>
        <div className="student-id-card2-div-3">
          {/* <div>QR</div> */}
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 100,
              width: "100%",
            }}
          >
            <QRCode
              size={300}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={value}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div>
            This Card is the Property of
            <br /> Beta College of Business & Technology..
            <br />
            If Found Please Return to us Immediatly.
          </div>
        </div>
      </div>
    </div>
  );
};

function HomeStudent({ studentId, admissionId }) {
  const navigate = useNavigate();
  const [admissionData, setAdmissionData] = useState("");
  const [moduleData, setModuleData] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const userData = sessionService.getUserData("userData");

  let ifStudent = userData.admissionManagement?.personalData?.studentId;
  useEffect(() => {
    axiosService
      .get(
        `${STUDENT_PORTAL_URL.GET_PROFILE_DATA_BY_ID}${
          studentId || userData?.admissionManagement?.personalData?.studentId
        }`
      )
      .then((response) => {
        const data = response?.data?.data;
        setAdmissionData(data);
      });
  }, [studentId, userData?.admissionManagement?.personalData?.studentId]);
  useEffect(() => {
    if (admissionData) {
      const programFeeMasterId =
        admissionData?.admission?.programDetails[0]?.programFee
          ?.programFeeMasterId;
      if (programFeeMasterId) {
        axiosService
          .get(
            `${STUDENT_PORTAL_URL.GET_MODULES_BY_PROGRAM_ID}${programFeeMasterId}`
          )
          .then((response) => {
            setModuleData(response?.data?.data);
          });
      }
    }
  }, [admissionData]);

  useEffect(() => {
    const handle = async () => {
      try {
        const response = await fetch(
          admissionData?.admission?.upload?.passportSizePhoto,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const blob = await response.blob();
        const reader = new FileReader();

        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          setProfileImage(reader.result);
        };
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    if (admissionData?.admission?.upload?.passportSizePhoto) {
      handle();
    }
  }, [admissionData?.admission?.upload?.passportSizePhoto]);

  function hasPermission(permission) {
    let userPermissions = sessionService?.hasPermissionByButton(permission);
    return userPermissions;
  }
  // const uploadedPhoto = admissionData?.admission?.upload?.passportSizePhoto;
  const [docName, setDocName] = useState("");
  const [pdfData, setPdfData] = useState("");
  const [downloadType, setDownloadType] = useState(null);
  const handleEyeClick = (docName, type) => {
    axiosService
      .get(
        `${DOCUMENT_URLS.GET_ADMISSION_BY_ID}${
          admissionId || userData?.admissionManagement?.admissionId
        }`
      )
      .then((response) => {
        const data = response?.data?.data;
        setDownloadType(type);
        setDocName(docName);
        setPdfData(data);
      });
  };

  return (
    <div className="home-student-main-con ">
      {!studentId && (
        <div className="student-portal-header-container sticky-header flex-row gap-2">
          <div className="d-flex align-items-center gap-3">
            <span className="communication-management-title-text">Home</span>
          </div>
          <div className="d-none d-lg-flex">
            <button
              className={`communication-save-head-button ${
                ""
                // !admissionData?.admission?.upload?.passportSizePhoto &&
                // profileImage === null && "opacity-50"
              }
              `}
              // className="communication-save-head-button opacity-50"
              style={{ marginRight: "10px" }}
              // disabled={!admissionData?.admission?.upload?.passportSizePhoto}
              // disabled={profileImage === null}
              onClick={() => setTriggerDownload(!triggerDownload)}
            >
              Download ID Card
            </button>
            <button
              className="user-head-button"
              style={{ display: "inline", marginRight: "10px" }}
              onClick={() => navigate("/student-portal/edit-profile-student")}
            >
              Edit Profile
            </button>
            {hasPermission("Home: view") && (
              <button
                className="communication-save-head-button"
                onClick={() =>
                  navigate("/student-portal/view-profile-student", {
                    state: {
                      studentId:
                        studentId ||
                        userData?.admissionManagement?.personalData?.studentId,
                    },
                  })
                }
              >
                View Profile
              </button>
            )}
          </div>

          <Dropdown className="d-lg-none">
            <Dropdown.Toggle
              className="lead-table-head-button"
              id="dropdown-basic"
            >
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0" align="end" flip>
              <Dropdown.Item
                disabled={!admissionData?.admission?.upload?.passportSizePhoto}
                onClick={() => setTriggerDownload(!triggerDownload)}
                className={`${
                  !admissionData?.admission?.upload?.passportSizePhoto &&
                  "opacity-50"
                }`}
              >
                Download ID Card
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigate("/student-portal/edit-profile-student")}
              >
                Edit Profile
              </Dropdown.Item>
              {hasPermission("Home: view") && (
                <Dropdown.Item
                  onClick={() =>
                    navigate("/student-portal/view-profile-student", {
                      state: {
                        studentId:
                          studentId ||
                          userData?.admissionManagement?.personalData
                            ?.studentId,
                      },
                    })
                  }
                >
                  View Profile
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <div className="student-portal-head-container flex-sm-row gap-2 ">
        <div className="row g-2 gap-2 gap-lg-0">
          <div className="col-12 col-lg-auto gap-4 d-flex">
            {profileImage !== null ? (
              <img
                alt="img"
                src={profileImage}
                style={{
                  height: "110px",
                  borderRadius: "10px",
                  color: "#283557",
                  width: "110px",
                }}
              />
            ) : (
              <img
                alt="img"
                src={useravatar}
                style={{
                  height: "110px",
                  borderRadius: "10px",
                  color: "#283557",
                  width: "110px",
                }}
              />
            )}
            <div className="home-student-alignment">
              <span className="NameText m-0 ml-4 row g-0">
                {admissionData?.admission?.personalData ? (
                  <>
                    {admissionData.admission.personalData.title}.
                    {admissionData.admission.personalData.firstName}
                    {admissionData.admission.personalData.middleName}{" "}
                    {admissionData.admission.personalData.lastName}
                  </>
                ) : (
                  "-"
                )}
              </span>
              <span className="SubText-data m-0 row g-0 align-items-start">
                <div className="d-flex align-items-center p-0">
                  <span className="align-self-start">
                    <ProfileViewIcon />
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      paddingLeft: "2px",
                    }}
                  >
                    {admissionData?.admission?.programDetails[0]?.program ||
                      "-"}
                  </span>
                </div>
              </span>

              <span
                className="Sub-Text-home m-0 g-0 row mt-2"
                style={{ fontSize: "13px" }}
              >
                {" "}
                @
                {studentId ||
                  userData?.admissionManagement?.personalData?.studentId}
              </span>

              <span
                className="Sub-Text-home m-0 g-0 row mt-2"
                style={{ fontSize: "13px" }}
              >
                {userData?.admissionManagement?.personalData
                  ?.osapAccessNumber &&
                  "@" +
                    userData?.admissionManagement?.personalData
                      ?.osapAccessNumber}
              </span>
            </div>
          </div>
          <div className="col-12 col-lg-auto home-student-alignment">
            <div className="containerStyle column d-flex">
              <div className="columnStyle">
                <div className="innerContainerStyle">
                  <div className="textHeadExams1">Upcoming Exams</div>
                  <span className="numberStyle" style={{ color: "#047BC1" }}>
                    {admissionData?.upcomingExams}
                  </span>
                </div>
              </div>
              <div className="columnStyle">
                <div className="innerContainerStyle">
                  <div className="textHeadExams1">Pending Fees</div>
                  <span
                    className="numberStyle"
                    style={{ color: "#FFFFFF", position: "relative" }}
                  >
                    {admissionData?.pendingFees}
                    <FaStar className="star-icon" />
                  </span>
                </div>
              </div>
              <div className="columnStyle">
                <div className="innerContainerStyle">
                  <div className="textHeadExams1">Upcoming Events</div>
                  <span className="numberStyle" style={{ color: "#F63A3A" }}>
                    {admissionData?.upcomingEvents}
                  </span>
                </div>
              </div>
              <div className="columnStyle">
                <div className="innerContainerStyle">
                  <div className="textHeadExams1">Shared Documents</div>
                  <span className="numberStyle" style={{ color: "#59C37B" }}>
                    {admissionData?.sharedDocuments}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-auto">
            <div className="d-flex flex-column gap-3 ps-2">
              <div className="d-flex gap-1 home-head-text">
                <TfiEmail style={{ color: "#047BC1", marginRight: "10px" }} />

                {admissionData?.admission?.personalData?.alternateEmail
                  ? admissionData?.admission?.personalData?.alternateEmail
                  : admissionData?.admission?.personalData?.email}
              </div>
              <div className="d-flex gap-3 ">
                <div className="d-flex gap-1 home-sub-text">
                  <VscCallOutgoing
                    style={{ color: "#047BC1", marginRight: "5px" }}
                  />

                  {admissionData?.admission?.mailingAddress?.mobile1 || "-"}
                </div>
                <div className="d-flex gap-1 home-sub-text">
                  <VscCallOutgoing
                    style={{ color: "#047BC1", marginRight: "5px" }}
                  />

                  {admissionData?.admission?.mailingAddress?.mobile2 || "-"}
                </div>
              </div>
              <div className="home-sub-text gap-2 d-flex">
                <LocationLogo />

                {admissionData?.admission?.mailingAddress ? (
                  <>
                    {admissionData.admission.mailingAddress.streetNo},
                    {admissionData.admission.mailingAddress.unit},
                    {admissionData.admission.mailingAddress.street},
                    {admissionData.admission.mailingAddress.province},
                    {admissionData.admission.mailingAddress.city},
                    {admissionData.admission.mailingAddress.country} -
                    {admissionData.admission.mailingAddress.postalCode}
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-0">
        <div className="col-12 col-lg-8 ">
          <div className="mt-2">
            <span className=" Doc-head">Program Information</span>
            <div
              className="mt-1 custom-scrollbar"
              style={{ overflowX: "auto", width: "100%" }}
            >
              <table style={{ minWidth: "100%" }}>
                <thead className="snap-education-table-head">
                  <tr>
                    <th className="table-data-view-profile">Program Name</th>
                    <th className="table-data-view-profile">
                      Program Duration
                    </th>
                    <th className="table-data-view-profile">Total Modules</th>
                    <th className="table-data-view-profile">Total Credits</th>
                  </tr>
                </thead>
                <tbody>
                  {admissionData?.admission?.programDetails?.map((program) => (
                    <tr key={program?.programId} className="snap-tr">
                      <td className="table-data-view-profile">
                        {program?.program || "-"}
                      </td>
                      <td className="table-data-view-profile">
                        {program?.programDuration || "-"}
                      </td>
                      <td className="table-data-view-profile">
                        {program?.totalModules || "-"}
                      </td>
                      <td className="table-data-view-profile">
                        {program?.totalCredits || "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-3">
            <span className=" Doc-head">Program Modules</span>
            <div
              className="mt-1 custom-scrollbar"
              style={{ overflowX: "auto", width: "100%" }}
            >
              <table style={{ minWidth: "100%" }}>
                <thead className="snap-education-table-head">
                  <tr>
                    <th
                      className="table-data-view-profile"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      Module{"\n"}Code
                    </th>
                    <th
                      className="table-data-view-profile"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      Module{"\n"}Name
                    </th>
                    <th
                      className="table-data-view-profile"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      Total Module Time {"\n"}(TMT) hours
                    </th>
                    <th
                      className="table-data-view-profile"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      Guided Learning{"\n"} Hours (GLH)
                    </th>
                    <th
                      className="table-data-view-profile"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      Beta {"\n"}Credits
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {moduleData
                    ? moduleData?.map((module) => (
                        <tr key={module?.moduleId} className="snap-tr">
                          <td className="table-data-view-profile">
                            {module?.moduleCode || "-"}
                          </td>
                          <td className="table-data-view-profile">
                            {module?.moduleName || "-"}
                          </td>
                          <td className="table-data-view-profile">
                            {module?.totalHours || "-"}
                          </td>
                          <td className="table-data-view-profile">
                            {module?.totalCredits || "-"}
                          </td>
                          <td className="table-data-view-profile">
                            {module?.betaCredits || "-"}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-4 d-flex flex-column justify-content-lg-end">
          <div className="">
            <div className="mt-2">
              <span className="Doc-head"> My Tasks & Events</span>
            </div>
            <div className="datepicker-container">
              <DatePicker
                className="datepicker-student"
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                dateFormat="MMMM d, yyyy"
                showMonthYearPicker={false}
                open={true}
                formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 3)}
              />
            </div>
          </div>

          <div className="mt-2">
            <span className="Doc-head">Recent Documents</span>

            <div className="Link-Container header-Contained-home">
              <div className="d-flex row g-0 doc-container">
                <span className="home-doc-text">
                  Conditional Letter of Acceptance
                </span>
                <span
                  className="home-sub-doc"
                  onClick={() => {
                    handleEyeClick(
                      "Conditional Letter of Acceptance",
                      "download"
                    );
                  }}
                >
                  {" "}
                  Available for download
                </span>
              </div>
              <div className="d-flex row g-0 doc-container">
                <span className="home-doc-text"> Letter of Acceptance</span>
                <span
                  className="home-sub-doc"
                  onClick={() => {
                    handleEyeClick("Letter of Acceptance", "download");
                  }}
                >
                  {" "}
                  Available for download
                </span>
              </div>

              <div className="d-flex row g-0 doc-container">
                <span className="home-doc-text"> Fees Invoice</span>
                <span
                  className="home-sub-doc"
                  onClick={() => {
                    handleEyeClick("Fees Invoice", "download");
                  }}
                >
                  {" "}
                  Available for download
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {docName === "Conditional Letter of Acceptance" && (
        <CLOAForDocument
          downloadType={downloadType}
          close={() => {
            setDownloadType(null);
          }}
          pdfData={pdfData}
        />
      )}
      {docName === "Letter of Acceptance" && (
        <FLOAForDocument
          downloadType={downloadType}
          close={() => {
            setDownloadType(null);
          }}
          pdfData={pdfData}
        />
      )}
      {docName === "Fees Invoice" &&
        (downloadType === "download" ? (
          navigate("/fee-finance/my-invoice")
        ) : downloadType === "view" ? (
          <FeesInvoice
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        ) : null)}
      {ifStudent && (
        <div style={{ position: "absolute", left: -9999 }}>
          <MyComponent
            admissionData={admissionData}
            triggerDownload={triggerDownload}
            setTriggerDownload={setTriggerDownload}
            profileImage={profileImage}
          />
        </div>
      )}
    </div>
  );
}

export default HomeStudent;
