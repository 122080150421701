import AddExtraCurricular from "modules/student-portal/components/student-portal-extra-curricular/add-exrta-curricular/AddExtraCurricular";
import ViewExtraCurricular from "modules/student-portal/components/student-portal-extra-curricular/view-extra-curricular/ViewExtraCurricular";
import AddStudentPortalParticipation from "modules/student-portal/components/student-portal-participation/add-participation/AddStudentPortalParticipation";
import ViewStudentPortalParticipation from "modules/student-portal/components/student-portal-participation/view-participation/ViewStudentPortalParticipation";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import sessionService from "services/sessionstorage.service";
import "utils/student-portal.css";
import AllStudentPortal from "../all-students-portal/AllStudentPortal";
import DocumentRequest from "../document-request-faculty-view/DocumentRequest";
import FacultyAllStudentNavbar from "../faculty-all-students-navbar/FacultyAllStudentNavbar";
import ViewProfileStudent from "../home/viewprofile/ViewProfileStudent";
import HomeStudent from "../home/viewprofile/homestudent/HomeStudent";
import Policies from "../policies/Policies";
import Documents from "../student-portal-documents/Documents";
import PreStudentDocuments from "../student-portal-documents/PreStudent";
import StudentPortalFeedback from "../student-portal-feedback/StudentPortalFeedback";
import StudentPortalPartialTranscript from "../student-portal-partial-transcript/StudentPortalPartialTranscript";
import AddStudentPortalService from "../student-portal-service/add-student-portal-service/AddStudentPortalService";
import StudentPortalService from "../student-portal-service/list-student-portal-service/StudentPortalService";
import ServicesFacultyView from "../student-portal-service/services-faculty-view/ServicesFacultyView";
import StudentPortalSchedules from "../student-portal-schedules/StudentPortalSchedules";
import EditStudentProfile from "../home/viewprofile/homestudent/EditStudentProfile";

function StudentPortalRoutes() {
  const location = useLocation();
  const naviation = useNavigate();
  const user = sessionService.getUserData("userData");

  useEffect(() => {
    if (location.pathname === "/student-portal") {
      if (user?.role?.name === "student") {
        naviation("/student-portal/home-student");
      } else if (user?.role?.name === "prestudent") {
        naviation("/student-portal/pre-student-view-documents");
      } else if (user?.role?.name === "office-staff") {
        naviation("/student-portal/document-request-faculty");
      } else {
        naviation("/student-portal/all-students-portal");
      }
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="all-students-portal" element={<AllStudentPortal />} />
      <Route path="view-student" element={<FacultyAllStudentNavbar />} />
      <Route
        path="view-students-portal-extracurricular"
        element={<ViewExtraCurricular />}
      />
      <Route
        path="add-students-portal-extracurricular"
        element={<AddExtraCurricular />}
      />
      <Route
        path="students-portal-service"
        element={<StudentPortalService />}
      />
      <Route
        path="add-students-portal-service"
        element={<AddStudentPortalService />}
      />
      <Route path="policies" element={<Policies />} />

      <Route path="home-student" element={<HomeStudent />} />
      <Route path="view-profile-student" element={<ViewProfileStudent />} />
      <Route path="edit-profile-student" element={<EditStudentProfile />} />
      <Route
        path="view-students-portal-participation"
        element={<ViewStudentPortalParticipation />}
      />
      <Route
        path="add-students-portal-participation"
        element={<AddStudentPortalParticipation />}
      />
      <Route
        path="partial-transcript-student"
        element={<StudentPortalPartialTranscript />}
      />
      <Route path="feedback-student" element={<StudentPortalFeedback />} />
      <Route path="schedules-student" element={<StudentPortalSchedules />} />

      <Route path="document-request-faculty" element={<DocumentRequest />} />
      <Route path="service-faculty" element={<ServicesFacultyView />} />
      <Route
        path="view-documents"
        element={
          <Documents
            admissionId={user?.admissionManagement?.admissionId}
            studentId={user?.admissionManagement?.personalData?.studentId}
            studentType={user?.studentType}
          />
        }
      />
      <Route
        path="pre-student-view-documents"
        element={<PreStudentDocuments />}
      />
    </Routes>
  );
}

export default StudentPortalRoutes;
