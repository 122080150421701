import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import sessionstorageService from "services/sessionstorage.service";
import CollapseIcon from "../../../../assets/images/collapse.svg";
import CollapseIcon1 from "../../../../assets/images/collapse1.svg";
import beta from "../../../../assets/images/icons/beta.svg";
import beta1 from "../../../../assets/images/icons/beta1.svg";
import "./SideBar.css";
import AdminLogo from "assets/image-components/AdminLogo";
import ApplicationLogo from "assets/image-components/ApplicationLogo";
import CampaignsLogo from "assets/image-components/CampaignsLogo";
import CommunicationLogo from "assets/image-components/CommunicationLogo";
import CrmIcon from "assets/image-components/CrmIcon";
import StudentPortalLogo from "assets/image-components/StudentPortalLogo";
import AcademicsLogo from "assets/image-components/AcademicLogo";
import FeesLogo from "assets/image-components/FeesLogo";
import TimeTableLogo from "assets/image-components/TimeTableLogo";
import AttendanceLogo from "assets/image-components/AttendanceLogo";
import DocumentLogo from "assets/image-components/DocumentLogo";
import ExamLogo from "assets/image-components/ExamLogo";
import HrModuleLogo from "assets/image-components/hRModule";
import { AUTH_URLS } from "core/auth/constants/auth-urls";
import { axiosService } from "services/axios.service";
import DashboardLogo from "assets/image-components/DashboardLogo";

function SideBar() {
  const location = useLocation();
  const crmPermission = "CRM: view";
  const admissionPermission = "Admission Management: view";
  const systemPermission = "System Administration: view";
  const PreDocumentPermission = "Pre-Student-Docs: view";
  const preonboardingPermission = "Pre-onBoarding: view";
  const studentPortalPermission = "Student Portal: view";

  const communicationManagementPermission =
    hasPermission("Circulars: view") ||
    hasPermission("Forums: view") ||
    hasPermission("Discussions: view") ||
    hasPermission("QueriesFeedbackChat: view") ||
    hasPermission("News: view");

  const dashboardPermission =
    hasPermission("Faculty Dashboard: view") ||
    hasPermission("Student Dashboard: view");
  console.log(dashboardPermission);
  console.log(hasPermission(), "HAAAAAAAAAAA");

  const academicsManagementPermission =
    hasPermission("Academics Home: view") ||
    hasPermission("All Programs: view") ||
    hasPermission("All Modules: view") ||
    hasPermission("My Program: view") ||
    hasPermission("LookUp: view");

  const timetableManagementPermission =
    hasPermission("My Calendar: view") ||
    hasPermission("Class Schedules: view") ||
    hasPermission("Resources: view") ||
    hasPermission("Student Calendar: view") ||
    hasPermission("Faculty Calendar: view");

  const feeAndFinancePermission =
    hasPermission("Student Invoices: view") ||
    hasPermission("Fee Plans: view") ||
    hasPermission("Sponser Invoices: view") ||
    hasPermission("My Invoices: view");

  const attendanceManagementPermission =
    hasPermission("My Classes: view") ||
    hasPermission("Class Attendance: view") ||
    hasPermission("My Attendance (faculty): view") ||
    hasPermission("Faculty Attendance: view") ||
    hasPermission("My Attendance (Student): view");

  const examsAndMarkSheetPermission =
    hasPermission("My Exams: view") ||
    hasPermission("My Exams (Faculty): view");
  const campaignsPermission =
    hasPermission("All Campaigns: view") ||
    hasPermission("Campaign Messages: view");

  const examModulesPermission =
    hasPermission("Exam Dashboard: view") ||
    hasPermission("All Exam: view") ||
    hasPermission("Question Bank: view") ||
    hasPermission("Manage Marks: view") ||
    hasPermission("New Exam: view");

  const hrPermission =
    hasPermission("Holiday Calendar & Leaves: view") ||
    hasPermission("All Faculty: view");

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);

    return userPermissions;
  }

  const [collapsed, setCollaped] = useState(true);
  const userData = sessionstorageService.getUserData("userData");

  const [isRead, setIsRead] = useState(false);
  const [totalNotify, setTotalNotify] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosService.get(
          `${AUTH_URLS.GET_ALL_CIRCULAR_NOTIFICATION}/${userData?.userId}`
        );
        if (response.status === 200) {
          const responseData = response?.data?.data;
          setIsRead(responseData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchDataForServiceDocs = async () => {
      try {
        const response = await axiosService.get(
          `${AUTH_URLS.GET_TOTAL_NO_OF_NOTIFY}`
        );
        if (response.status === 200) {
          const responseData = response?.data?.data;
          setTotalNotify(responseData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchDataForServiceDocs();
  }, [location.pathname]);

  return (
    <div
      className={`sidebar-container d-none d-lg-block ${
        collapsed ? "collapsed" : "expanded"
      }`}
    >
      <div
        className={
          collapsed
            ? "sidebar-logo-container d-flex justify-content-center"
            : "sidebar-logo-container ps-1"
        }
      >
        <img
          src={collapsed ? beta1 : beta}
          className="sidebar-logo"
          alt="beta-logo"
        />
      </div>
      <div className="sidebar-list-container">
        {dashboardPermission && (
          <NavLink
            to={"dashboard-management"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <DashboardLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <DashboardLogo color={"#283557"} />
            </div>

            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Dashboard
            </span>
          </NavLink>
        )}
        {hasPermission(crmPermission) && (
          <NavLink
            to={"/lead-management"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <CrmIcon color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <CrmIcon color={"#283557"} />
            </div>

            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              CRM
            </span>
          </NavLink>
        )}
        {hasPermission(admissionPermission) && (
          <NavLink
            to={"admission-management"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <ApplicationLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <ApplicationLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Admission Management
            </span>
          </NavLink>
        )}
        {userData?.role?.roleId > 5 && (
          <NavLink
            to={"profile"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <ApplicationLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <ApplicationLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Profile
            </span>
          </NavLink>
        )}
        {campaignsPermission && (
          <NavLink
            to={"campaigns"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <CampaignsLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <CampaignsLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Campaigns
            </span>
          </NavLink>
        )}
        {hasPermission(preonboardingPermission) && (
          <NavLink
            to={"pre-onboarding"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <CampaignsLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <CampaignsLogo color={"#283557"} />
            </div>

            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Pre-Onboarding
            </span>
          </NavLink>
        )}
        {hasPermission(studentPortalPermission) && (
          <NavLink
            to={"student-portal"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && " sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              {totalNotify > 0 && userData?.role?.name === "super admin" && (
                <span className="total-notify-with-number-active">
                  {totalNotify}
                </span>
              )}
              <StudentPortalLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              {totalNotify > 0 && userData?.role?.name === "super admin" && (
                <span className="total-notify-with-number">{totalNotify}</span>
              )}
              <StudentPortalLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Student Portal
            </span>
          </NavLink>
        )}
        {communicationManagementPermission && (
          <NavLink
            to={"communication-management"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && "sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              {isRead === false && <span className="notification-dot"></span>}
              <CommunicationLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              {isRead === false && (
                <span className="notification-dot-active"></span>
              )}
              <CommunicationLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Communications
            </span>
          </NavLink>
        )}
        {hasPermission(PreDocumentPermission) && (
          <NavLink
            to={"student-portal"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && " sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <StudentPortalLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <StudentPortalLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Documents
            </span>
          </NavLink>
        )}
        {hasPermission(systemPermission) && (
          <NavLink
            to={"user-management"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && " sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <AdminLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <AdminLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              System Administration
            </span>
          </NavLink>
        )}
        {academicsManagementPermission && (
          <NavLink
            to={"academics-management"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && " sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <AcademicsLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <AcademicsLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Academics Management
            </span>
          </NavLink>
        )}
        {timetableManagementPermission && (
          <NavLink
            to={"timetable-management"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && " sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <TimeTableLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <TimeTableLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Time-Table Management
            </span>
          </NavLink>
        )}

        {examModulesPermission && (
          <NavLink
            to={"exam-module"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <ExamLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <ExamLogo color={"#283557"} />
            </div>

            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Exam Module
            </span>
          </NavLink>
        )}

        {attendanceManagementPermission && (
          <NavLink
            to={"attendance-management"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && " sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <AttendanceLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <AttendanceLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              Attendance Management
            </span>
          </NavLink>
        )}

        {hrPermission && (
          <NavLink
            to={"hr-module"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <HrModuleLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <HrModuleLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text"
              }`}
            >
              HR Module
            </span>
          </NavLink>
        )}

        {feeAndFinancePermission && (
          <NavLink
            to={"fee-finance"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && " sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <FeesLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <FeesLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text wrap"
              }`}
            >
              {" "}
              {collapsed ? (
                <React.Fragment>
                  Fees &<br />
                  Finance
                </React.Fragment>
              ) : (
                "Fees & Finance"
              )}
            </span>
          </NavLink>
        )}
        {examsAndMarkSheetPermission && (
          <NavLink
            to={"exam-marksheets"}
            style={({ isActive }) =>
              isActive
                ? { backgroundColor: "#f69f3a", color: "#333333" }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed && " sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <DocumentLogo color={"#F69F3A"} />
            </div>
            <div className="hover-show-logo">
              <DocumentLogo color={"#283557"} />
            </div>
            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text wrap"
              }`}
            >
              {" "}
              {collapsed ? (
                <React.Fragment>
                  Exams &
                  <br />
                  Marksheets
                </React.Fragment>
              ) : (
                "Exams & Marksheets"
              )}
            </span>
          </NavLink>
        )}
        <div
          className={`sidebar-list-collapse-container ${
            collapsed && " sidebar-list-inner-container-collapsed-icon"
          }`}
          onClick={() => setCollaped(!collapsed)}
        >
          <img
            src={collapsed ? CollapseIcon1 : CollapseIcon}
            className="sidebar-collapse-icon"
            alt="collapse"
          />
          <span
            className={`sidebar-list-text ${
              collapsed && "collapsed-sidebar-list-text"
            }`}
          >
            {collapsed ? "Expand" : "Collapse"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
