import ViewIcon from "assets/image-components/ViewIcon";
import "./FacultyDashboard.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { axiosService } from "services/axios.service";
import { DASHBOARD_URLS } from "modules/dashboard-management/constants/dashboard_urls";
import sessionService from "services/sessionstorage.service";
import { useLocation } from "react-router-dom";
import { GrNext, GrPrevious } from "react-icons/gr";

function FacultyDashboard() {
  const userData = sessionService.getUserData("userData");
  const location = useLocation();
  const [dashboardData, setDashboardData] = useState(null);
  console.log(dashboardData, "dashboardData");
  useEffect(() => {
    axiosService
      .get(`${DASHBOARD_URLS.GET_DASHBOARD_FACULTY_BY_ID}${userData?.userId}`)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data.data;
          setDashboardData(responseData);
        }
      });
  }, [location.pathname]);
  const programNames = dashboardData?.coursewiseStudents
    ? Object.keys(dashboardData.coursewiseStudents)
    : null;
  const studentCounts = dashboardData?.coursewiseStudents
    ? Object.values(dashboardData.coursewiseStudents)
    : null;
  const totalStudents = dashboardData?.totalStudentCount;
  const percentageCounts = studentCounts
    ? studentCounts.map((count) => (count / totalStudents) * 100)
    : 0;
  console.log(percentageCounts, "percentageCounts");
  const barseries = [
    {
      name: "Overall Percentage",
      data: percentageCounts,
    },
  ];

  const baroptions = {
    chart: {
      width: "100%",
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20px",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: programNames,

      labels: {
        style: {
          colors: "#FFFFFF",
          fontFamily: '"Poppins", sans-serif',
          fontSize: "10px",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
        formatter: function (value) {
          return Math.round(value);
        },
      },
    },

    fill: {
      opacity: 1,
      colors: ["#FFA500"],
    },
    tooltip: {
      theme: "dark",
      style: {
        fontSize: "12px",
        colors: ["#FFFFFF"],
      },
      marker: {
        show: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#666",
    },
    scroll: {
      enabled: true,
    },
  };

  const daysOfWeek = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];

  const attendancePercentages = daysOfWeek.map((day) => {
    const totalCount = dashboardData?.studentAttendanceCount[day]
      ? dashboardData?.studentAttendanceCount[day]?.totalCount
      : 0;
    const studentCount = dashboardData?.studentAttendanceCount[day]
      ? dashboardData?.studentAttendanceCount[day]?.studentCount
      : 0;

    if (totalCount === 0) return 0;

    return (studentCount / totalCount) * 100;
  });

  const series = [
    {
      name: "Student Percentage",
      data: attendancePercentages,
    },
  ];
  const option = {
    chart: {
      height: 350,
      type: "radar",
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFA500"],
    stroke: {
      width: 2,
      curve: "smooth",
    },
    fill: {
      opacity: 0.2,
      colors: ["#FFA500"],
    },
    markers: {
      size: 4,
      colors: ["#FFA500"],
      hover: {
        size: 6,
      },
    },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },

    tooltip: {
      theme: "dark",
      style: {
        fontSize: "12px",
        colors: ["#FFFFFF"],
      },
      marker: {
        show: false,
      },
    },
  };

  const MonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const overallAttendancePercent = MonthNames.map((month) => {
    const totalCount =
      dashboardData?.yearwiseAttendanceCount[month]?.totalCount || 0;
    const studentCount =
      dashboardData?.yearwiseAttendanceCount[month]?.studentCount || 0;

    if (totalCount === 0) return 0;

    return (studentCount / totalCount) * 100;
  });
  const lineSeries = [
    {
      name: "Overall Percentage",
      data: overallAttendancePercent,
    },
  ];
  const lineOption = {
    chart: {
      width: "100%",
      type: "area",
      height: 350,
      redrawOnWindowResize: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFA500"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "#FFFFFF",
          fontFamily: '"Poppins", sans-serif',
          fontSize: "10px",
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
        formatter: function (value) {
          return Math.round(value);
        },
      },
    },

    grid: {
      borderColor: "#666",
    },
    grid: {
      show: false,
      padding: {
        right: 10,
        left: 10,
      },
    },

    tooltip: {
      theme: "dark",
      style: {
        fontSize: "12px",
        colors: ["#000000"],
      },
      marker: {
        show: false,
      },
    },
  };
  const [currentExamIndex, setCurrentExamIndex] = useState(0);

  const allExams = dashboardData?.upcomingExams
    ? Object.keys(dashboardData?.upcomingExams).flatMap((date) =>
        dashboardData?.upcomingExams[date].map((exam) => {
          const formattedDate = new Date(exam.scheduledDate).toLocaleDateString(
            "en-US",
            { month: "short", day: "numeric" }
          );
          const formattedStartTime = new Date(
            `1970-01-01T${exam.scheduledTime}`
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          const formattedEndTime = new Date(
            `1970-01-01T${exam.endTime}`
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return {
            moduleName: exam?.module?.module?.moduleName,
            formattedDate,
            formattedStartTime,
            formattedEndTime,
          };
        })
      )
    : null;

  const examsToShow = allExams
    ? allExams?.slice(currentExamIndex, currentExamIndex + 2)
    : null;

  const handleNext = () => {
    if (currentExamIndex + 2 < allExams.length) {
      setCurrentExamIndex(currentExamIndex + 2);
    }
  };

  const handlePrevious = () => {
    if (currentExamIndex > 0) {
      setCurrentExamIndex(currentExamIndex - 2);
    }
  };
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const classesForSelectedDate =
    dashboardData?.upcomingClasses[selectedDate.toISOString().split("T")[0]] ||
    [];

  const handlePreviousDate = () => {
    if (selectedDate > currentDate) {
      const prevDate = new Date(selectedDate);
      prevDate.setDate(prevDate.getDate() - 1);
      setSelectedDate(prevDate);
    }
  };

  const handleNextDate = () => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setSelectedDate(nextDate);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formattedDates = dashboardData?.upcomingClasses
    ? Object.keys(dashboardData?.upcomingClasses).map((date) =>
        formatDate(date)
      )
    : null;
  const parsedFormattedDates =
    formattedDates &&
    formattedDates.map((date) => {
      const [day, month, year] = date.split("-");
      return new Date(`${year}-${month}-${day}`);
    });

  const isFormattedDate = (date) => {
    if (parsedFormattedDates) {
      return parsedFormattedDates.some(
        (formattedDate) => formattedDate.toDateString() === date.toDateString()
      );
    }
    return false;
  };

  return (
    <div className="dashboard-main-container">
      <div className="d-flex row dashboard-sub-container">
        <div
          className="col-12 col-md-8 "
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <div className="dashboard-count-container">
            <div className="dashboard-single-box ">
              <span className="dashboard-box-title-con">
                <span className="dashboard-count-view-title">
                  No. of Active OSAP
                </span>
                <span>
                  <ViewIcon />
                </span>
              </span>
              <span className="dashboard-count-items">
                <text>{dashboardData?.activeOsapStudents}</text>
              </span>
            </div>
            <div className="dashboard-single-box ">
              <span className="dashboard-box-title-con">
                <span className="dashboard-count-view-title">
                  International Students
                </span>
                <span>
                  <ViewIcon />
                </span>
              </span>
              <span className="dashboard-count-items">
                <text>{dashboardData?.internationalStudents}</text>
              </span>
            </div>
            <div className="dashboard-single-box ">
              <span className="dashboard-box-title-con">
                <span className="dashboard-count-view-title">
                  Total Courses
                </span>
                <span>
                  <ViewIcon />
                </span>
              </span>
              <span className="dashboard-count-items">
                <text>{dashboardData?.totalCourses}</text>
              </span>
            </div>
            <div className="dashboard-single-box ">
              <span className="dashboard-box-title-con">
                <span className="dashboard-count-view-title">
                  Classes this week
                </span>
                <span>
                  <ViewIcon />
                </span>
              </span>
              <span className="dashboard-count-items">
                <text>{dashboardData?.classThisWeek}</text>
              </span>
            </div>
          </div>
          {dashboardData && (
            <div className="dashboard-graph-container">
              <div className="dashboard-graph-box">
                <span className="dashboard-box-title-con">
                  <span className="dashboard-count-view-title">
                    Course-wise student %
                  </span>
                  <span>
                    <ViewIcon />
                  </span>
                </span>

                <div className="chartContainerStyle">
                  <ReactApexChart
                    options={baroptions}
                    series={barseries}
                    type="bar"
                    width={"700px"}
                    height="250px"
                  />
                </div>
              </div>
              <div className="dashboard-radar-graph-box">
                <span className="dashboard-box-title-con-radar">
                  <span className="dashboard-count-view-title">
                    Attendance % by Day of the week
                  </span>
                  <span>
                    <ViewIcon />
                  </span>
                </span>
                <div className="radar-chart-container">
                  <ReactApexChart
                    options={option}
                    series={series}
                    type="radar"
                    height={350}
                  />
                </div>
              </div>
            </div>
          )}
          {dashboardData && (
            <div className="dashboard-line-graph-container">
              <div className="dashboard-line-graph-box">
                <span className="dashboard-box-title-con">
                  <span className="dashboard-count-view-title">
                    Overall Attendance %
                  </span>
                  <span>
                    <ViewIcon />
                  </span>
                </span>
                <div>
                  <ReactApexChart
                    options={lineOption}
                    series={lineSeries}
                    type="area"
                    height={250}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="col-12 col-md-4 "
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <div className="dashboard-right-con">
            <span className="dashboard-box-title-con">
              <span className="dashboard-count-view-title">
                Upcoming Classes
              </span>
              <span>
                <ViewIcon />
              </span>
            </span>

            <div className="dashboard-datepicker-container">
              <DatePicker
                className="datepicker-student"
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                inline
                dateFormat="MMMM d, yyyy"
                formatWeekDay={(nameOfDay) => nameOfDay?.slice(0, 3)}
                dayClassName={(date) => {
                  if (date.toDateString() === selectedDate.toDateString()) {
                    return "selected-date";
                  }
                  if (isFormattedDate(date)) {
                    return "formatted-date";
                  }
                  return "";
                }}
              />
            </div>

            <div className="d-flex column justify-content-between mb-2">
              <span className="dashboard-date-head">
                {selectedDate.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
              <div>
                <span
                  onClick={handlePreviousDate}
                  className={`arrow-button ${
                    selectedDate <= currentDate && "disabled"
                  }`}
                >
                  <GrPrevious />
                </span>
                <span onClick={handleNextDate} className="arrow-button">
                  <GrNext />
                </span>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "7px" }}
            >
              {classesForSelectedDate.length > 0 ? (
                classesForSelectedDate.map((classItem, index) => (
                  <div className="upcoming-view-container" key={index}>
                    <span
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="7.5" cy="7.5" r="7.5" fill="#F69F3A" />
                      </svg>

                      <span className="dashboard-upcoming-module-text">
                        {classItem?.module?.module?.moduleName}
                      </span>
                    </span>
                    <div className="upcoming-inner-con">
                      <span className="dashboard-upcoming-module-time">
                        {classItem?.startTime} - {classItem?.endTime}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <span>No classes for this date</span>
              )}
            </div>
          </div>

          <div className="dashboard-bottom-con">
            <span className="dashboard-box-title-con dashboard-bottom-title">
              <span className="dashboard-count-view-title">Upcoming Exams</span>
              <span>
                <span
                  style={{
                    display: "flex",
                    gap: "15px",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "row",
                    }}
                  >
                    <span
                      onClick={handlePrevious}
                      disabled={currentExamIndex === 0}
                    >
                      <GrPrevious
                        size={12}
                        style={{
                          color: currentExamIndex === 0 ? "#8c8c8c" : "#ffffff",
                        }}
                      />
                    </span>
                    <span
                      onClick={handleNext}
                      disabled={
                        allExams
                          ? currentExamIndex + 2 >= allExams.length
                          : null
                      }
                    >
                      <GrNext
                        size={12}
                        style={{
                          color: allExams
                            ? currentExamIndex + 2 >= allExams.length
                              ? "#8c8c8c"
                              : "#ffffff"
                            : null,
                        }}
                      />
                    </span>
                  </div>
                  <ViewIcon />
                </span>
              </span>
            </span>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                overflow: "hidden",
              }}
            >
              {examsToShow && examsToShow.length > 0 ? (
                examsToShow.map((exam, index) => (
                  <div className="upcoming-view-container" key={index}>
                    <span
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="7.5" cy="7.5" r="7.5" fill="#59C37B" />
                      </svg>

                      <span className="dashboard-upcoming-module-text">
                        {exam.moduleName}
                      </span>
                    </span>
                    <div className="upcoming-inner-con">
                      <span className="dashboard-upcoming-module-time">
                        {exam.formattedDate} - {exam.formattedStartTime} -{" "}
                        {exam.formattedEndTime}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <span>No Upcoming Exams to Show</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FacultyDashboard;
