import React from "react";

function ViewIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 7C9.82843 7 10.5 6.32843 10.5 5.5C10.5 4.67157 9.82843 4 9 4C8.17157 4 7.5 4.67157 7.5 5.5C7.5 6.32843 8.17157 7 9 7Z"
        fill="#F7931D"
      />
      <path
        d="M9 14C9.82843 14 10.5 13.3284 10.5 12.5C10.5 11.6716 9.82843 11 9 11C8.17157 11 7.5 11.6716 7.5 12.5C7.5 13.3284 8.17157 14 9 14Z"
        fill="#F7931D"
      />
    </svg>
  );
}

export default ViewIcon;
