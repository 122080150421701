import AdminLogo from "assets/image-components/AdminLogo";
import ApplicationLogo from "assets/image-components/ApplicationLogo";
import CampaignsLogo from "assets/image-components/CampaignsLogo";
import CrmIcon from "assets/image-components/CrmIcon";
import StudentPortalLogo from "assets/image-components/StudentPortalLogo";
import useravatar from "assets/images/user-avatar.svg";
import { ADMISSION_URL_FOR_STUDENT } from "modules/pre-onboarding/components/constants/admission-application-student-urls";
import { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { IoClose, IoReorderThreeOutline } from "react-icons/io5";
import { RxTriangleDown } from "react-icons/rx";
import { TfiReload } from "react-icons/tfi";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import sessionstorageService from "services/sessionstorage.service";
import mobilelogo from "../../../../assets/images/mobilelogo.svg";
import "./Header.css";
import ExamLogo from "assets/image-components/ExamLogo";
import CommunicationLogo from "assets/image-components/CommunicationLogo";
import AcademicsLogo from "assets/image-components/AcademicLogo";
import FeesLogo from "assets/image-components/FeesLogo";
import TimeTableLogo from "assets/image-components/TimeTableLogo";
import AttendanceLogo from "assets/image-components/AttendanceLogo";
import DocumentLogo from "assets/image-components/DocumentLogo";
import HrModuleLogo from "assets/image-components/hRModule";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { AUTH_URLS } from "core/auth/constants/auth-urls";

function Header() {
  const location = useLocation();
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("CRM");
  const navigate = useNavigate();
  const userData = sessionstorageService.getUserData("userData");
  const firstName = userData.firstName ? userData.firstName : "";
  const lastName = userData.lastName ? userData.lastName : "";

  const crmPermission = "CRM: view";
  const admissionPermission = "Admission Management: view";
  const systemPermission = "System Administration: view";
  const preonboardingPermission = "Pre-onBoarding: view";
  const studentPortalPermission = "Student Portal: view";

  const PreDocumentPermission = "Pre-Student-Docs: view";

  const communicationManagementPermission =
    hasPermission("Circulars: view") ||
    hasPermission("Forums: view") ||
    hasPermission("Discussions: view") ||
    hasPermission("QueriesFeedbackChat: view") ||
    hasPermission("News: view");
  const academicsManagementPermission =
    hasPermission("Academics Home: view") ||
    hasPermission("All Programs: view") ||
    hasPermission("All Modules: view") ||
    hasPermission("My Program: view");

  const timetableManagementPermission =
    hasPermission("My calendar: view") ||
    hasPermission("Class Schedules: view") ||
    hasPermission("Resources: view") ||
    hasPermission("Faculty Calendar: view");

  const feeAndFinancePermission =
    hasPermission("Student Invoices: view") ||
    hasPermission("Fee Plans: view") ||
    hasPermission("Sponser Invoices: view");
  const attendanceManagementPermission =
    hasPermission("My Classes: view") ||
    hasPermission("Class Attendance: view") ||
    hasPermission("My Attendance (faculty): view") ||
    hasPermission("Faculty Attendance: view");
  const examsAndMarkSheetPermission =
    hasPermission("My Exams: view") ||
    hasPermission("My Exams (Faculty): view");
  const campaignsPermission =
    hasPermission("All Campaigns: view") ||
    hasPermission("Campaign Messages: view");
  const examModulesPermission = hasPermission("Exam Dashboard: view");
  const hrPermission =
    hasPermission("Holiday Calendar & Leaves: view") ||
    hasPermission("All Faculty: view");

  const admissionId = sessionstorageService.getAdmissionId("admissionId");
  const [studentRegistraionLink, setStudentRegistraionLink] = useState(
    "/pre-onboarding/create-personal-data"
  );

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const firstPathSegment = pathParts[1];
    setPath(firstPathSegment);

    if (firstPathSegment === "lead-management") {
      setTitle("CRM");
    } else if (firstPathSegment === "admission-management") {
      setTitle("Admission Management");
    } else if (firstPathSegment === "user-management") {
      setTitle("System Administration");
    } else if (firstPathSegment === "pre-onboarding") {
      setTitle("Pre-Onboarding");
    } else if (firstPathSegment === "student-portal") {
      setTitle("Student Portal");
    } else if (firstPathSegment === "communication-management") {
      setTitle("Communications");
    } else if (firstPathSegment === "academics-management") {
      setTitle("Academic Management");
    } else if (firstPathSegment === "timetable-management") {
      setTitle("Time-Table Management");
    } else if (firstPathSegment === "fee-finance") {
      setTitle("Fee And Finance");
    } else if (firstPathSegment === "attendance-management") {
      setTitle("Attendance Management");
    } else if (firstPathSegment === "campaigns") {
      setTitle("Campaigns");
    } else if (firstPathSegment === "exam-module") {
      setTitle("Exam Module");
    } else if (firstPathSegment === "hr-module") {
      setTitle("HR Module");
    } else if (firstPathSegment === "profile") {
      setTitle("Profile");
    } else if (firstPathSegment === "exam-marksheets") {
      setTitle("Exams & Marksheets");
    } else if (firstPathSegment === "dashboard-management") {
      setTitle("Dashboard Management");
    }
    if (admissionId) {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          if (data?.page === "PERSONAL") {
            setStudentRegistraionLink("/pre-onboarding/create-mailing-address");
          } else if (data?.page === "MAILING") {
            setStudentRegistraionLink(
              "/pre-onboarding/create-guardian-details"
            );
          } else if (data?.page === "GUARDIAN") {
            setStudentRegistraionLink(
              "/pre-onboarding/create-academic-professional"
            );
          } else if (data?.page === "ACADEMIC") {
            setStudentRegistraionLink(
              "/pre-onboarding/create-employement-work-experience"
            );
          } else if (data?.page === "EMPLOYMENT") {
            setStudentRegistraionLink(
              "/pre-onboarding/create-other-information"
            );
          } else if (data?.page === "OTHER") {
            setStudentRegistraionLink(
              "/pre-onboarding/create-list-upload-files"
            );
          } else if (data?.page === "FILEUPLOAD") {
            setStudentRegistraionLink("/pre-onboarding/create-student-profile");
          } else if (data?.page === "STUDENTPROFILE") {
            setStudentRegistraionLink("/pre-onboarding/create-student-profile");
          } else if (data?.page === "PROGRAM") {
            setStudentRegistraionLink("/pre-onboarding/create-student-profile");
          } else if (data?.page === "FEE") {
            setStudentRegistraionLink("/pre-onboarding/create-student-profile");
          } else if (data?.page === "PAYMENT") {
            setStudentRegistraionLink("/pre-onboarding/create-student-profile");
          }
        });
    }
  }, [location.pathname]);

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);
    return userPermissions;
  }

  const [isNavbarCollapsed, setNavbarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar((prevState) => !prevState);
  };
  const Logout = () => {
    sessionStorage.clear();
    if (userData?.studentType === "STAFF") {
      navigate("/");
    } else {
      navigate(`/${userData.studentType}`);
    }
  };
  useEffect(() => {
    setNavbarCollapsed(false);
    setShowSidebar(false);
  }, [location.pathname]);

  const [isScrollable, setIsScrollable] = useState(false);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const navbarRef = useRef(null);

  const scrollToStart = () => {
    if (navbarRef.current) {
      navbarRef.current.scrollTo({ left: 0, behavior: "smooth" });
      setTimeout(updateScrollButtons, 300);
    }
  };

  const scrollToEnd = () => {
    if (navbarRef.current) {
      const { scrollWidth, clientWidth } = navbarRef.current;
      navbarRef.current.scrollTo({
        left: scrollWidth - clientWidth,
        behavior: "smooth",
      });
      setTimeout(updateScrollButtons, 300);
    }
  };

  const checkScrollable = () => {
    if (navbarRef.current) {
      const { scrollWidth, clientWidth } = navbarRef.current;
      setIsScrollable(scrollWidth > clientWidth);
      updateScrollButtons();
    }
  };

  const updateScrollButtons = () => {
    if (navbarRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = navbarRef.current;
      setAtStart(scrollLeft === 0);
      setAtEnd(scrollLeft + clientWidth >= scrollWidth - 1);
    }
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, []);

  useEffect(() => {
    if (navbarRef.current) {
      navbarRef.current.addEventListener("scroll", updateScrollButtons);
    }
    return () => {
      if (navbarRef.current) {
        navbarRef.current.removeEventListener("scroll", updateScrollButtons);
      }
    };
  }, []);
  const [docNotify, setDocNotify] = useState(null);
  const [serviceNotify, setServiceNotify] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosService.get(
          `${AUTH_URLS.GET_ALL_CIRCULAR_NOTIFICATION}/${userData?.userId}`
        );
        if (response.status === 200) {
          const responseData = response?.data?.data;
          setIsRead(responseData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchNotifyDocs = async () => {
      try {
        const response = await axiosService.get(
          `${AUTH_URLS.GET_DOCUMENT_NOTIFY}`
        );
        if (response.status === 200) {
          const responseData = response?.data?.data;
          setDocNotify(responseData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchNotifyServices = async () => {
      try {
        const response = await axiosService.get(
          `${AUTH_URLS.GET_SERVICE_NOTIFY}`
        );
        if (response.status === 200) {
          const responseData = response?.data?.data;
          setServiceNotify(responseData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchNotifyServices();
    fetchNotifyDocs();
  }, [location.pathname]);
  return (
    <nav className="navbar navbar-expand-lg header-container wrap-nowrap">
      <div
        className="container-fluid g-0 justify-between-start"
        style={{ width: "100%", height: "50px", flexWrap: "nowrap" }}
      >
        <span
          className="d-none d-lg-block header-title-text-wrap"
          onClick={() => {
            if (title === "CRM") {
              navigate("/lead-management/home-leads");
            }
          }}
          style={{
            cursor: "pointer",
          }}
        >
          {title}
        </span>

        <div
          className="d-lg-none ps-2 d-flex col-7 header-title-text-con"
          style={{ height: "100%" }}
        >
          <div className="d-flex align-items-center">
            <img src={mobilelogo} style={{ height: "35px" }} alt="beta-logo" />
            <div
              className="d-flex align-items-center "
              onClick={() => {
                setShowSidebar(true);
              }}
            >
              <h6
                className={`${
                  title.split(" ").length > 1
                    ? "header-title-text-wrap-mob"
                    : "header-title-text"
                }`}
              >
                {title}
              </h6>
              <RxTriangleDown className="header-dropdown-icon" />
            </div>
          </div>
        </div>

        <hr className="d-none d-lg-block hr-line"></hr>

        {isScrollable && !atStart && (
          <button className="more-text ms-2 ms-lg-0" onClick={scrollToStart}>
            &lt;
          </button>
        )}
        <div
          ref={navbarRef}
          className={`collapse navbar-collapse ${
            isNavbarCollapsed ? "show" : ""
          }`}
          id="navbarSupportedContent"
          style={{
            maxWidth: "60%",
            overflowX: "auto",
          }}
          onScroll={checkScrollable}
        >
          {path === "lead-management" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission(crmPermission) ? (
                <NavLink
                  className="nav-link ms-2 ms-lg-0  "
                  to={"/lead-management/home-leads"}
                >
                  Home
                </NavLink>
              ) : (
                <div></div>
              )}
              {hasPermission(crmPermission) ? (
                <NavLink
                  to={"/lead-management/view-leads-table"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    (location.pathname === "/lead-management/create-leads" ||
                      location.pathname === "/lead-management/view-leads" ||
                      location.pathname === "/lead-management/create-leads") &&
                    "active"
                  }`}
                >
                  Leads
                </NavLink>
              ) : (
                <div></div>
              )}

              {hasPermission(crmPermission) ? (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   ${
                    location.pathname === "/lead-management/create-task" &&
                    "active"
                  }`}
                  to={"lead-management/view-task"}
                >
                  Tasks
                </NavLink>
              ) : (
                <div></div>
              )}
              {hasPermission(crmPermission) ? (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   ${
                    location.pathname === "/lead-management/view-reports" &&
                    "active"
                  }`}
                  to={"lead-management/view-reports"}
                >
                  Reports
                </NavLink>
              ) : (
                <div></div>
              )}
            </ul>
          )}

          {path === "user-management" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission(systemPermission) ? (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   ${
                    location.pathname === "/user-management/add-users" &&
                    "active"
                  }`}
                  to={"user-management/view-users"}
                  style={({ isActive }) =>
                    isActive
                      ? { color: "#f69f3a", borderBottom: "3px solid #f69f3a" }
                      : { color: "#ffffff" }
                  }
                >
                  Users
                </NavLink>
              ) : (
                <div></div>
              )}
              {hasPermission(systemPermission) && (
                <NavLink
                  to={"user-management/role-list"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    location.pathname === "/user-management/add-role" &&
                    "active"
                  }`}
                >
                  Roles
                </NavLink>
              )}
            </ul>
          )}

          {path === "admission-management" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission(admissionPermission) ? (
                <NavLink
                  to={"/admission-management/view-admission"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    location.pathname.startsWith("/admission-management/") &&
                    "active"
                  }`}
                >
                  All Applications
                </NavLink>
              ) : (
                <div></div>
              )}
            </ul>
          )}
          {path === "pre-onboarding" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission(preonboardingPermission) ? (
                <NavLink
                  to={studentRegistraionLink}
                  className={`nav-link ms-2 ms-lg-0   ${
                    ((location.pathname === studentRegistraionLink &&
                      "active") ||
                      location.pathname ===
                        "/pre-onboarding/create-personal-data" ||
                      location.pathname ===
                        "/pre-onboarding/create-mailing-address" ||
                      location.pathname ===
                        "/pre-onboarding/create-guardian-details" ||
                      location.pathname ===
                        "/pre-onboarding/create-academic-professional" ||
                      location.pathname ===
                        "/pre-onboarding/create-employement-work-experience" ||
                      location.pathname ===
                        "/pre-onboarding/create-other-information" ||
                      location.pathname ===
                        "/pre-onboarding/create-list-upload-files" ||
                      location.pathname ===
                        "/pre-onboarding/create-student-profile") &&
                    "active"
                  }`}
                >
                  Registration
                </NavLink>
              ) : (
                <div></div>
              )}
              {hasPermission(preonboardingPermission) ? (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                 ${
                   (location.pathname ===
                     "/pre-onboarding/create-program-details" ||
                     location.pathname === "/pre-onboarding/create-financial" ||
                     location.pathname ===
                       "/pre-onboarding/paymentsubmission" ||
                     location.pathname ===
                       "/pre-onboarding/cloa-paymentsubmission" ||
                     location.pathname ===
                       "/pre-onboarding/view-application-student") &&
                   "active"
                 }`}
                  to={"pre-onboarding/view-application-student"}
                >
                  Applications
                </NavLink>
              ) : (
                <div></div>
              )}
            </ul>
          )}
          {path === "student-portal" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-md-2">
              {hasPermission("All Students: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                  ${
                    location.pathname === "/student-portal/student-snapshot" ||
                    (location.pathname === "/student-portal/view-student" &&
                      "active")
                  }`}
                  to={"student-portal/all-students-portal"}
                >
                  All Students
                </NavLink>
              )}
              {hasPermission("Student Home: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   

                  ${
                    location.pathname === "/student-portal/home-student" ||
                    (location.pathname ===
                      "/student-portal/view-profile-student" &&
                      "active") ||
                    (location.pathname ===
                      "/student-portal/edit-profile-student" &&
                      "active")
                  }`}
                  to={"student-portal/home-student"}
                >
                  Home
                </NavLink>
              )}
              {hasPermission("Documents: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   `}
                  to={"student-portal/view-documents"}
                >
                  Documents
                </NavLink>
              )}
              {hasPermission("Pre-Student-Docs: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   `}
                  to={"student-portal/pre-student-view-documents"}
                >
                  Pre-Student Documents
                </NavLink>
              )}
              {hasPermission("Extracurricular: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0  `}
                  to={"student-portal/view-students-portal-extracurricular"}
                >
                  Extracurricular
                </NavLink>
              )}
              {hasPermission("Policies: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0  `}
                  to={"student-portal/policies"}
                >
                  Policies
                </NavLink>
              )}
              {hasPermission("Services: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0  `}
                  to={"student-portal/students-portal-service"}
                >
                  Services
                </NavLink>
              )}
              {hasPermission("All Service Requests: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0  `}
                  to={"student-portal/service-faculty"}
                >
                  Services
                  {serviceNotify > 0 && (
                    <span className="service-notify-with-number">
                      {serviceNotify}
                    </span>
                  )}
                </NavLink>
              )}
              {hasPermission("Schedule: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0  `}
                  to={"student-portal/schedules-student"}
                >
                  Schedules
                </NavLink>
              )}
              {hasPermission("All Document Requests: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0  `}
                  to={"student-portal/document-request-faculty"}
                >
                  Document Requests
                  {docNotify > 0 && (
                    <span className="notify-with-number">{docNotify}</span>
                  )}
                </NavLink>
              )}
              {hasPermission("Partial Transcript: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0  `}
                  to={"student-portal/partial-transcript-student"}
                >
                  Partial Transcript
                </NavLink>
              )}

              {hasPermission("Feedback: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0`}
                  to={"student-portal/feedback-student"}
                >
                  Feedback
                </NavLink>
              )}
              {hasPermission("Participation: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                  ${
                    location.pathname ===
                      "/student-portal/view-students-portal-participation" &&
                    "active"
                  }`}
                  to={"student-portal/view-students-portal-participation"}
                >
                  Participation
                </NavLink>
              )}
            </ul>
          )}
          {path === "communication-management" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              <NavLink
                className={`nav-link ms-2 ms-lg-0    `}
                to={"communication-management/queries"}
              >
                Queries
              </NavLink>
              <NavLink
                className={`nav-link ms-2 ms-lg-0    `}
                to={"communication-management/current-affairs"}
              >
                Current Affairs
              </NavLink>
              <NavLink
                className={`nav-link ms-2 ms-lg-0   
               ${
                 location.pathname ===
                   "/communication-management/view-circular" && "active"
               }`}
                to={"communication-management/circulars"}
              >
                {isRead === false && (
                  <span className="notification-dot-header"></span>
                )}
                Circulars
              </NavLink>
              <NavLink
                className={`nav-link ms-2 ms-lg-0   
                 ${
                   (location.pathname === "/communication-management/forums" ||
                     location.pathname ===
                       "/communication-management/view-discussion" ||
                     location.pathname ===
                       "/communication-management/view-forum-discussion") &&
                   "active"
                 }`}
                to={"communication-management/forums"}
              >
                Forums
              </NavLink>
              <NavLink
                className={`nav-link ms-2 ms-lg-0    `}
                to={"communication-management/view-notifications"}
              >
                Notifications
              </NavLink>
            </ul>
          )}
          {path === "academics-management" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission("Academics Home: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"academics-management/academics-home"}
                >
                  Home
                </NavLink>
              )}
              {hasPermission("All Programs: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                  ${
                    (location.pathname ===
                      "/academics-management/add-program" &&
                      "active") ||
                    (location.pathname ===
                      "/academics-management/view-program" &&
                      "active")
                  }`}
                  to={"academics-management/all-program"}
                >
                  All Programs
                </NavLink>
              )}
              {hasPermission("All Modules: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                  ${
                    (location.pathname === "/academics-management/add-module" &&
                      "active") ||
                    (location.pathname ===
                      "/academics-management/view-modules" &&
                      "active")
                  }`}
                  to={"academics-management/all-modules"}
                >
                  All Modules
                </NavLink>
              )}
              {hasPermission("LookUp: view") && (
                <NavLink
                  to={"academics-management/lookup"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    (location.pathname ===
                      "/academics-management/create-lookup" ||
                      location.pathname ===
                        "/academics-management/lookup-view" ||
                      location.pathname ===
                        "/academics-management/lookup-add") &&
                    "active"
                  }`}
                >
                  LookUp
                </NavLink>
              )}
              {hasPermission("My Program: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0`}
                  to={"academics-management/view-program"}
                >
                  My Program
                </NavLink>
              )}

              {hasPermission("My Program: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    ${
                    (location.pathname === "/academics-management/my-modules" &&
                      "active") ||
                    (location.pathname ===
                      "/academics-management/my-module-view" &&
                      "active")
                  }`}
                  to={"academics-management/my-modules"}
                >
                  My Modules
                </NavLink>
              )}
            </ul>
          )}
          {path === "timetable-management" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission("My Calendar: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"timetable-management/my-calendar"}
                >
                  My Calendar
                </NavLink>
              )}
              {hasPermission("Faculty Calendar: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"timetable-management/calendar"}
                >
                  Calendar
                </NavLink>
              )}
              {hasPermission("Student Calendar: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"timetable-management/student-calendar"}
                >
                  My Calendar
                </NavLink>
              )}
              {hasPermission("Class Schedules: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    ${
                    (location.pathname === "/timetable-management/add-class" &&
                      "active") ||
                    (location.pathname === "/timetable-management/list-class" &&
                      "active")
                  }`}
                  to={"timetable-management/list-class"}
                >
                  Class Schedules
                </NavLink>
              )}
              {hasPermission("Resources: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                 ${
                   location.pathname === "/timetable-management/add-resource" &&
                   "active"
                 }`}
                  to={"timetable-management/all-resources"}
                >
                  Resources
                </NavLink>
              )}
            </ul>
          )}
          {path === "exam-marksheets" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission("My Exams: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    ${
                    (location.pathname === "exam-marksheets/exam-questions" &&
                      "active") ||
                    (location.pathname === "exam-marksheets/my-exams" &&
                      "active")
                  }`}
                  to={"exam-marksheets"}
                >
                  My Exams
                </NavLink>
              )}
              {hasPermission("My Exams (Faculty): view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    ${
                    (location.pathname ===
                      "exam-marksheets/all-student-answers" &&
                      "active") ||
                    (location.pathname === "exam-marksheets/exam-answers" &&
                      "active")
                  }`}
                  to={"exam-marksheets"}
                >
                  Answer Review
                </NavLink>
              )}
            </ul>
          )}

          {path === "attendance-management" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission("My Classes: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    ${
                    (location.pathname ===
                      "/attendance-management/my-classes" &&
                      "active") ||
                    (location.pathname ===
                      "/attendance-management/view-attendance" &&
                      "active") ||
                    (location.pathname ===
                      "/attendance-management/mark-attendance" &&
                      "active")
                  }`}
                  to={"attendance-management/my-classes"}
                >
                  My Classes
                </NavLink>
              )}
              {hasPermission("Class Attendance: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    ${
                    location.pathname === "/attendance-management/list-class" &&
                    "active"
                    // (location.pathname ===
                    //   "/attendance-management/view-attendance" &&
                    //   "active") ||
                    // (location.pathname ===
                    //   "/attendance-management/mark-attendance" &&
                    //   "active")
                  }`}
                  to={"attendance-management/list-class"}
                >
                  Class Attendance
                </NavLink>
              )}
              {hasPermission("My Attendance (faculty): view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                 ${
                   location.pathname ===
                     "/attendance-management/add-resource" && "active"
                 }`}
                  to={"attendance-management/my-attendance-faculty"}
                >
                  My Attendance
                </NavLink>
              )}
              {hasPermission("My Attendance (Student): view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                 ${
                   location.pathname ===
                     "/attendance-management/add-resource" && "active"
                 }`}
                  to={"attendance-management/my-attendance-student"}
                >
                  My Attendance
                </NavLink>
              )}
              {hasPermission("Faculty Attendance: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0   
                 ${
                   location.pathname ===
                     "/attendance-management/faculty-attendance" && "active"
                 }`}
                  to={"attendance-management/faculty-attendance"}
                >
                  Faculty Attendance
                </NavLink>
              )}
            </ul>
          )}

          {path === "fee-finance" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission("Fee Plans: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"fee-finance/view-all-fee"}
                >
                  Fee Plans
                </NavLink>
              )}
              {hasPermission("Student Invoices: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"fee-finance/view-student-invoice"}
                >
                  Student Invoices
                </NavLink>
              )}
              {hasPermission("My Invoices: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"fee-finance/my-invoice"}
                >
                  My Invoices
                </NavLink>
              )}
              {hasPermission("My Invoices: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"fee-finance/my-receipt"}
                >
                  My Receipts
                </NavLink>
              )}
              {hasPermission("Sponser Invoices: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"fee-finance/view-sponsor-invoice"}
                >
                  Sponsor Invoices
                </NavLink>
              )}
            </ul>
          )}

          {path === "campaigns" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission("All Campaigns: view") && (
                <NavLink
                  to={"campaigns/all-campaigns"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    (location.pathname === "/campaigns/all-campaigns" ||
                      location.pathname === "/campaigns/create-campaign") &&
                    "active"
                  }`}
                >
                  All Campaigns
                </NavLink>
              )}
              {hasPermission("Campaign Messages: view") && (
                <NavLink
                  to={"campaigns/all-campaign-messages"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    (location.pathname === "/campaigns/all-campaign-messages" ||
                      location.pathname ===
                        "/campaigns/create-campaign-message") &&
                    "active"
                  }`}
                >
                  Campaign Messages
                </NavLink>
              )}

              {/* to be change role */}
              {hasPermission("Campaign Messages: view") && (
                <NavLink
                  to={"campaigns/contact-list"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    location.pathname === "/campaigns/contact-list" && "active"
                  }`}
                >
                  Contact List
                </NavLink>
              )}
            </ul>
          )}

          {path === "exam-module" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission("Exam Dashboard: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"exam-module/dashboard"}
                >
                  Dashboard
                </NavLink>
              )}

              {hasPermission("All Exam: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0    `}
                  to={"exam-module/all-exams"}
                >
                  All Exams
                </NavLink>
              )}

              {hasPermission("New Exam: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0 
                    ${
                      location.pathname === "/exam-module/view-exam" && "active"
                    }`}
                  to={"exam-module/create-exam"}
                >
                  Create New Exam
                </NavLink>
              )}

              {hasPermission("Question Bank: view") && (
                <NavLink
                  className={`nav-link ms-2 ms-lg-0 `}
                  to={"exam-module/question-bank"}
                >
                  Question Bank
                </NavLink>
              )}
              {/* {hasPermission("Manage Marks: view") && ( */}
              <NavLink
                className={`nav-link ms-2 ms-lg-0 `}
                to={"exam-module/manage-marks"}
              >
                MarkSheet Management
              </NavLink>
              {/* )} */}
            </ul>
          )}

          {path === "hr-module" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {hasPermission("All Faculty: view") && (
                <NavLink
                  to={"hr-module/view-all-faculty"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    (location.pathname === "/hr-module/view-all-faculty" ||
                      location.pathname === "/hr-module/view-faculty" ||
                      location.pathname === "/hr-module/new-employee" ||
                      location.pathname ===
                        "/hr-module/view-faculty-attendance") &&
                    "active"
                  }`}
                >
                  All Faculty
                </NavLink>
              )}
              {hasPermission("Holiday Calendar & Leaves: view") && (
                <NavLink
                  to={"hr-module/holiday-and-leaves"}
                  className={`nav-link ms-2 ms-lg-0   ${
                    (location.pathname ===
                      "/hr-module/holiday-and-leaves-calendar" ||
                      location.pathname === "/hr-module/holiday-and-leaves") &&
                    "active"
                  }`}
                >
                  Holiday Calendar & Leaves
                </NavLink>
              )}
            </ul>
          )}

          {path === "profile" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-md-5">
              <NavLink
                to={"profile/my-profile"}
                className={`nav-link ms-2 ms-md-0 ${
                  location.pathname === "profile/my-profile" && "active"
                }`}
              >
                My Profile
              </NavLink>
            </ul>
          )}
          {path === "dashboard-management" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-lg-5">
              {/* {hasPermission(systemPermission) ? ( */}
              <NavLink
                className={`nav-link ms-2 ms-lg-0   ${
                  (location.pathname === "dashboard-management/faculty-view" ||
                    location.pathname ===
                      "dashboard-management/student-view") &&
                  "active"
                }`}
                to={"/dashboard-management"}
                style={({ isActive }) =>
                  isActive
                    ? { color: "#f69f3a", borderBottom: "3px solid #f69f3a" }
                    : { color: "#ffffff" }
                }
              >
                Dashboard
              </NavLink>
              {/* ) : (
                <div></div>
              )} */}
            </ul>
          )}
        </div>

        {isScrollable && !atEnd && (
          <button className="more-text ms-2 ms-lg-0" onClick={scrollToEnd}>
            more &gt;
          </button>
        )}
        <div className="column d-flex profileName-Container gap-2 pe-1 ms-auto">
          <button
            onClick={() => {
              window.location.reload();
            }}
            style={{ background: "transparent", border: "none" }}
          >
            <TfiReload color={"#FFFFFF"} />
          </button>
          <span className="user-name-header">
            {firstName}
            <br />
            {lastName}
          </span>
          <Dropdown className="profile-container p-0">
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                border: 0,
                padding: 0,
              }}
              id="dropdown-basic"
            >
              <img
                src={userData?.profileImage || useravatar}
                className="header-nav-profile-image"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0">
              <Dropdown.Item onClick={Logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {!isNavbarCollapsed && (
            <button
              className="navbar-toggler p-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setNavbarCollapsed(true);
              }}
            >
              <span>
                <IoReorderThreeOutline className="toggle-icon" />
              </span>
            </button>
          )}

          {isNavbarCollapsed && (
            <button
              className="navbar-toggler p-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setNavbarCollapsed(false);
              }}
            >
              <span
                className="collapsed-icon-toggle"
                onClick={() => {
                  setNavbarCollapsed(false);
                }}
              >
                <IoClose className="toggle-icon" />
              </span>
            </button>
          )}
        </div>
      </div>

      <Drawer
        open={showSidebar}
        onClose={toggleSidebar}
        direction="left"
        className="header-sidebar-main"
        size={"90vw"}
        lockBackgroundScroll={true}
      >
        <div className="d-flex justify-content-between">
          <span className="header-title-text" style={{ fontSize: "20px" }}>
            Modules
          </span>

          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
            type="button"
          >
            <span
              onClick={() => {
                setShowSidebar(false);
              }}
            >
              <IoClose className="toggle-icon" />
            </span>
          </button>
        </div>

        <div className="sidebar-list-container">
          {hasPermission(crmPermission) ? (
            <NavLink
              to={"/lead-management"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <CrmIcon color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <CrmIcon color={"#283557"} />
              </div>

              <span className="sidebar-list-text">CRM</span>
            </NavLink>
          ) : (
            <div></div>
          )}
          {hasPermission(admissionPermission) ? (
            <NavLink
              to={"admission-management"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <ApplicationLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <ApplicationLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Admission Management</span>
            </NavLink>
          ) : (
            <div></div>
          )}
          {campaignsPermission && (
            <NavLink
              to={"campaigns"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <CampaignsLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <CampaignsLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Campaigns</span>
            </NavLink>
          )}
          {hasPermission(preonboardingPermission) ? (
            <NavLink
              to={"pre-onboarding"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <CampaignsLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <CampaignsLogo color={"#283557"} />
              </div>

              <span className="sidebar-list-text">Pre-Onboarding</span>
            </NavLink>
          ) : (
            <div></div>
          )}
          {hasPermission(studentPortalPermission) ? (
            <NavLink
              to={"student-portal"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <StudentPortalLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <StudentPortalLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Student Portal</span>
            </NavLink>
          ) : (
            <div></div>
          )}
          {communicationManagementPermission && (
            <NavLink
              to={"communication-management"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                {isRead === false && <span className="notification-dot"></span>}
                <CommunicationLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                {isRead === false && (
                  <span className="notification-dot-active"></span>
                )}
                <CommunicationLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Communications</span>
            </NavLink>
          )}
          {hasPermission(PreDocumentPermission) && (
            <NavLink
              to={"student-portal"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <StudentPortalLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <StudentPortalLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Documents</span>
            </NavLink>
          )}
          {hasPermission(systemPermission) && (
            <NavLink
              to={"user-management"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <AdminLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <AdminLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">System Administration</span>
            </NavLink>
          )}
          {academicsManagementPermission ? (
            <NavLink
              to={"academics-management"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <AcademicsLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <AcademicsLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Academics Management</span>
            </NavLink>
          ) : (
            <div></div>
          )}
          {timetableManagementPermission ? (
            <NavLink
              to={"timetable-management"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <TimeTableLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <TimeTableLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Time-Table Management</span>
            </NavLink>
          ) : hasPermission("Student Calendar: view") ? (
            <NavLink
              to={"timetable-management/student-calendar"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <TimeTableLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <TimeTableLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Time-Table Management</span>
            </NavLink>
          ) : null}
          {examModulesPermission && (
            <NavLink
              to={"exam-module"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <ExamLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <ExamLogo color={"#283557"} />
              </div>

              <span className="sidebar-list-text">Exam Module</span>
            </NavLink>
          )}

          {attendanceManagementPermission ? (
            <NavLink
              to={"attendance-management"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <AttendanceLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <AttendanceLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Attendance Management</span>
            </NavLink>
          ) : hasPermission("My Attendance (Student): view") ? (
            <NavLink
              to={"attendance-management/my-attendance-student"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <AttendanceLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <AttendanceLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Attendance Management</span>
            </NavLink>
          ) : null}
          {hrPermission && (
            <NavLink
              to={"hr-module"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <HrModuleLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <HrModuleLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">HR Module</span>
            </NavLink>
          )}

          {feeAndFinancePermission ? (
            <NavLink
              to={"fee-finance"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <FeesLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <FeesLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Fees & Finance</span>
            </NavLink>
          ) : hasPermission("My Invoices: view") ? (
            <NavLink
              to={"fee-finance/my-invoice"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <FeesLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <FeesLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Fees & Finance</span>
            </NavLink>
          ) : null}
          {examsAndMarkSheetPermission && (
            <NavLink
              to={"exam-marksheets"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <DocumentLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <DocumentLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Exams & Marksheets</span>
            </NavLink>
          )}

          {userData?.role?.roleId > 5 && (
            <NavLink
              to={"profile"}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#f69f3a", color: "#333333" }
                  : { color: "#ffffff" }
              }
              className="sidebar-list-inner-container"
            >
              <div className="hover-hide-logo">
                <ApplicationLogo color={"#F69F3A"} />
              </div>
              <div className="hover-show-logo">
                <ApplicationLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Profile</span>
            </NavLink>
          )}
        </div>
      </Drawer>
    </nav>
  );
}

export default Header;
