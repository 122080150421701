import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "utils/communication-management.css";
import "utils/forum-discussion.css";
import Dashboard from "../components/dashboard/Dashboard";
import NewForm from "../components/create-new-form/new-form/NewForm";
import AllExams from "../components/all-exams/AllExams";
import ViewForm from "../components/create-new-form/new-form/ViewForm";
import StudentMarks from "../components/manage-marks/StudentMarks";
import sessionService from "services/sessionstorage.service";

function ExamModuleRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = sessionService.getUserData();
  const role = userData?.role?.name;
  useEffect(() => {
    if (location.pathname === "/exam-module") {
      navigate("/exam-module/all-exams");
    }
  }, [location.pathname, navigate]);
  useEffect(() => {
    if (location.pathname === "/exam-module") {
      if (role === "faculty") {
        navigate("/exam-module/all-exams");
      } else {
        navigate("/exam-module/manage-marks");
      }
    }
  }, [location.pathname, navigate]);
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="create-exam" element={<NewForm />} />
      <Route path="view-exam" element={<ViewForm />} />
      <Route path="all-exams" element={<AllExams />} />
      <Route path="manage-marks" element={<StudentMarks />} />
    </Routes>
  );
}

export default ExamModuleRoutes;
